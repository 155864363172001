import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import OnboardingStepper from "../components/OnboardingStepper/OnboardingStepper";
import TripCard from "../components/TripOverview/TripCard";
import { Formik } from "formik";
import { ArrowRightAlt } from "@mui/icons-material";
import { useRecoilState } from "recoil";
import { CurrentPassengerState } from "../recoil/PassengerAtom";
import { useNavigate } from "react-router-dom";
import { PassengerDrinkInformationDTO } from "../types/passenger";
import {
  IconBeer,
  IconBottle,
  IconCup,
  IconGlassCocktail,
} from "@tabler/icons-react";
import FormRatingControl from "../components/FormRatingControl/FormRatingControl";
import {
  createDrinkInfo,
  getPassengerDetails,
} from "../services/passengerService";

export default function DrinkPreferences() {
  const [currentPassenger, setCurrentPassenger] = useRecoilState(
    CurrentPassengerState
  );
  const navigate = useNavigate();
  var initialValues: PassengerDrinkInformationDTO = {
    isDrinkCoke: 3,
    isDrinkDietCoke: 3,
    isDrinkSprite: 3,
    isDrinkDietSprite: 3,
    isGingerAle: 3,
    isCoffee: 3,
    isIcedCoffee: 3,
    isBlackTea: 3,
    isGreenTea: 3,
    isFloralTea: 3,
    isIcedTea: 3,
    isWholeMilk: 3,
    isMilk: 3,
    isSkimMilk: 3,
    isTapWater: 3,
    isBottledWater: 3,
    isFlavoredWater: 3,
    isGatorade: 3,
    isOrangeJuice: 3,
    isAppleJuice: 3,
    isPineappleJuice: 3,
    isCranberryJuice: 3,
    isTonicWater: 3,
    isClubSoda: 3,
    otherNonAlcoholicDrinks: "",
    isNoAlchohol: false,
    isLightBeer: 3,
    isDarkBeer: 3,
    isIPA: 3,
    isCorona: 3,
    isWhiteWine: 3,
    isRedWine: 3,
    isRose: 3,
    isSweetRedWine: 3,
    isPort: 3,
    isSparklingChampagne: 3,
    isHardTeaLemonade: 3,
    isSeltzers: 3,
    isGin: 3,
    isVodka: 3,
    isFlavoredVodka: 3,
    isRum: 3,
    isFlavoredRum: 3,
    isTequila: 3,
    isWhiskey: 3,
    isScotch: 3,
    isLiqueurs: 3,
    isBitters: 3,
    isSimpleSyrup: 3,
    isTripleSec: 3,
    isGrenadine: 3,
    isSeltzer: 3,
    otherAlcoholicDrinks: "",
    otherMixers: "",
    drinkAndCocktailRequests: "",
  };
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <OnboardingStepper activeStep={5} />
        </Grid>
        <Grid item xs={12}>
          <TripCard
            startDate={currentPassenger.trip?.startDate}
            startLocation={currentPassenger.trip?.startLocation}
            endDate={currentPassenger.trip?.endDate}
            endLocation={currentPassenger.trip?.endLocation}
            firstName={currentPassenger.firstName}
            lastName={currentPassenger.lastName}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 4,
              borderTopColor: "#b47f49",
              borderTopWidth: "4px",
              borderStyle: "solid",
              borderRightWidth: "0px",
              borderLeftWidth: "0px",
              borderBottomWidth: "0",
            }}
            elevation={12}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h4">Drink Preferences</Typography>
              </Grid>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Formik
                  initialValues={initialValues}
                  onSubmit={(values) => {
                    createDrinkInfo(currentPassenger.id, values).then((dres) =>
                      getPassengerDetails(currentPassenger).then((res) => {
                        setCurrentPassenger(res.data);
                        navigate("/");
                      })
                    );
                  }}
                >
                  {({
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Alert severity="info" variant="outlined">
                            <Stack direction="column">
                              <Typography variant="body1">
                                Please rate the following drink preferences. We
                                will do our best to accommodate everyone’s
                                preferences, but please understand that some
                                items may not always be available.
                              </Typography>
                              <List>
                                <ListItem>1 - Cannot Have</ListItem>
                                <ListItem>2 - Dislike</ListItem>
                                <ListItem>3 - Neutral</ListItem>
                                <ListItem>4 - Like</ListItem>
                                <ListItem>5 - Must Have</ListItem>
                              </List>
                            </Stack>
                          </Alert>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h5">
                            <IconCup /> Non-Alcoholic Drinks
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Coke"
                            name="isDrinkCoke"
                            value={values.isDrinkCoke}
                            onChange={(e, val) => {setFieldValue('isDrinkCoke', val);}}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Diet Coke"
                            name="isDrinkDietCoke"
                            value={values.isDrinkDietCoke}
                            onChange={(e, val) => {setFieldValue('isDrinkDietCoke', val);}}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Sprite"
                            name="isDrinkSprite"
                            value={values.isDrinkSprite}
                            onChange={(e, val) => {setFieldValue('isDrinkSprite', val);}}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Diet Sprite"
                            name="isDrinkDietSprite"
                            value={values.isDrinkDietSprite}
                            onChange={(e, val) => {setFieldValue('isDrinkDietSprite', val);}}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Ginger Ale"
                            name="isGingerAle"
                            value={values.isGingerAle}
                            onChange={(e, val) => {setFieldValue('isGingerAle', val);}}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Coffee"
                            name="isCoffee"
                            value={values.isCoffee}
                            onChange={(e, val) => {setFieldValue('isCoffee', val);}}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Iced Coffee"
                            name="isIcedCoffee"
                            value={values.isIcedCoffee}
                            onChange={(e, val) => {setFieldValue('isIcedCoffee', val);}}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Black Tea"
                            name="isBlackTea"
                            value={values.isBlackTea}
                            onChange={(e, val) => {setFieldValue('isBlackTea', val);}}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Green Tea"
                            name="isGreenTea"
                            value={values.isGreenTea}
                            onChange={(e, val) => {setFieldValue('isGreenTea', val);}}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Floral Tea"
                            name="isFloralTea"
                            value={values.isFloralTea}
                            onChange={(e, val) => {setFieldValue('isFloralTea', val);}}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Iced Tea"
                            name="isIcedTea"
                            value={values.isIcedTea}
                            onChange={(e, val) => {setFieldValue('isIcedTea', val);}}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Whole Milk"
                            name="isWholeMilk"
                            value={values.isWholeMilk}
                            onChange={(e, val) => {setFieldValue('isWholeMilk', val);}}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Milk"
                            name="isMilk"
                            value={values.isMilk}
                            onChange={(e, val) => {setFieldValue('isMilk', val);}}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Skim Milk"
                            name="isSkimMilk"
                            value={values.isSkimMilk}
                            onChange={(e, val) => {setFieldValue('isSkimMilk', val);}}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Tap Water"
                            name="isTapWater"
                            value={values.isTapWater}
                            onChange={(e, val) => {setFieldValue('isTapWater', val);}}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Bottled Water"
                            name="isBottledWater"
                            value={values.isBottledWater}
                            onChange={(e, val) => {setFieldValue('isBottledWater', val);}}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Flavored Water"
                            name="isFlavoredWater"
                            value={values.isFlavoredWater}
                            onChange={(e, val) => {setFieldValue('isFlavoredWater', val);}}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Gatorade"
                            name="isGatorade"
                            value={values.isGatorade}
                            onChange={(e, val) => {setFieldValue('isGatorade', val);}}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Orange Juice"
                            name="isOrangeJuice"
                            value={values.isOrangeJuice}
                            onChange={(e, val) => {setFieldValue('isOrangeJuice', val);}}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Apple Juice"
                            name="isAppleJuice"
                            value={values.isAppleJuice}
                            onChange={(e, val) => {setFieldValue('isAppleJuice', val);}}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Pineapple Juice"
                            name="isPineappleJuice"
                            value={values.isPineappleJuice}
                            onChange={(e, val) => {setFieldValue('isPineappleJuice', val);}}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Cranberry Juice"
                            name="isCranberryJuice"
                            value={values.isCranberryJuice}
                            onChange={(e, val) => {setFieldValue('isCranberryJuice', val);}}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Tonic Water"
                            name="isTonicWater"
                            value={values.isTonicWater}
                            onChange={(e, val) => {setFieldValue('isTonicWater', val);}}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Club Soda"
                            name="isClubSoda"
                            value={values.isClubSoda}
                            onChange={(e, val) => {setFieldValue('isClubSoda', val);}}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="otherNonAlcoholicDrinks"
                            name="otherNonAlcoholicDrinks"
                            label={`Are there any other drinks you would like us to try to provide?`}
                            value={values.otherNonAlcoholicDrinks}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.otherNonAlcoholicDrinks &&
                              Boolean(errors.otherNonAlcoholicDrinks)
                            }
                            helperText={
                              touched.otherNonAlcoholicDrinks &&
                              errors.otherNonAlcoholicDrinks
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h4">
                            <IconBeer /> Alcoholic Drinks
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={<Checkbox checked={values.isNoAlchohol} />}
                            label="I do not drink. Please do not offer me alcohol"
                            name="isNoAlchohol"
                            onChange={handleChange}
                          />
                        </Grid>
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12}>
                            <Typography variant="h5">
                              <IconBeer />
                              {" Beer and Wine and Lighter Alcoholic Drinks"}
                            </Typography>
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12} md={6}>
                            <FormRatingControl
                              label="Light Beer"
                              name="isLightBeer"
                              value={values.isLightBeer}
                              onChange={(e, val) => {setFieldValue('isLightBeer', val);}}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12} md={6}>
                            <FormRatingControl
                              label="Dark Beer"
                              name="isDarkBeer"
                              value={values.isDarkBeer}
                              onChange={(e, val) => {setFieldValue('isDarkBeer', val);}}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12} md={6}>
                            <FormRatingControl
                              label="IPA"
                              name="isIPA"
                              value={values.isIPA}
                              onChange={(e, val) => {setFieldValue('isIPA', val);}}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12} md={6}>
                            <FormRatingControl
                              label="Corona"
                              name="isCorona"
                              value={values.isCorona}
                              onChange={(e, val) => {setFieldValue('isCorona', val);}}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12} md={6}>
                            <FormRatingControl
                              label="White Wine"
                              name="isWhiteWine"
                              value={values.isWhiteWine}
                              onChange={(e, val) => {setFieldValue('isWhiteWine', val);}}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12} md={6}>
                            <FormRatingControl
                              label="Red Wine"
                              name="isRedWine"
                              value={values.isRedWine}
                              onChange={(e, val) => {setFieldValue('isRedWine', val);}}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12} md={6}>
                            <FormRatingControl
                              label="Rosé"
                              name="isRose"
                              value={values.isRose}
                              onChange={(e, val) => {setFieldValue('isRose', val);}}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12} md={6}>
                            <FormRatingControl
                              label="Sweet Red Wine"
                              name="isSweetRedWine"
                              value={values.isSweetRedWine}
                              onChange={(e, val) => {setFieldValue('isSweetRedWine', val);}}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12} md={6}>
                            <FormRatingControl
                              label="Port Wine"
                              name="isPort"
                              value={values.isPort}
                              onChange={(e, val) => {setFieldValue('isPort', val);}}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12} md={6}>
                            <FormRatingControl
                              label="Sparkling Wine / Champagne"
                              name="isSparklingChampagne"
                              value={values.isSparklingChampagne}
                              onChange={(e, val) => {setFieldValue('isSparklingChampagne', val);}}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12} md={6}>
                            <FormRatingControl
                              label="Hard Teas / Lemonades"
                              name="isHardTeaLemonade"
                              value={values.isHardTeaLemonade}
                              onChange={(e, val) => {setFieldValue('isHardTeaLemonade', val);}}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12} md={6}>
                            <FormRatingControl
                              label="Seltzers"
                              name="isSeltzers"
                              value={values.isSeltzers}
                              onChange={(e, val) => {setFieldValue('isSeltzers', val);}}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12}>
                            <Typography variant="h5">
                              <IconBottle />
                              {" Liquors"}
                            </Typography>
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12} md={6}>
                            <FormRatingControl
                              label="Gin"
                              name="isGin"
                              value={values.isGin}
                              onChange={(e, val) => {setFieldValue('isGin', val);}}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12} md={6}>
                            <FormRatingControl
                              label="Vodka"
                              name="isVodka"
                              value={values.isVodka}
                              onChange={(e, val) => {setFieldValue('isVodka', val);}}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12} md={6}>
                            <FormRatingControl
                              label="Flavored Vodka"
                              name="isFlavoredVodka"
                              value={values.isFlavoredVodka}
                              onChange={(e, val) => {setFieldValue('isFlavoredVodka', val);}}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12} md={6}>
                            <FormRatingControl
                              label="Rum"
                              name="isRum"
                              value={values.isRum}
                              onChange={(e, val) => {setFieldValue('isRum', val);}}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12} md={6}>
                            <FormRatingControl
                              label="Flavored Rum"
                              name="isFlavoredRum"
                              value={values.isFlavoredRum}
                              onChange={(e, val) => {setFieldValue('isFlavoredRum', val);}}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12} md={6}>
                            <FormRatingControl
                              label="Tequila"
                              name="isTequila"
                              value={values.isTequila}
                              onChange={(e, val) => {setFieldValue('isTequila', val);}}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12} md={6}>
                            <FormRatingControl
                              label="Whiskey"
                              name="isWhiskey"
                              value={values.isWhiskey}
                              onChange={(e, val) => {setFieldValue('isWhiskey', val);}}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12} md={6}>
                            <FormRatingControl
                              label="Scotch"
                              name="isScotch"
                              value={values.isScotch}
                              onChange={(e, val) => {setFieldValue('isScotch', val);}}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12} md={6}>
                            <FormRatingControl
                              label="Liqueurs"
                              name="isLiqueurs"
                              value={values.isLiqueurs}
                              onChange={(e, val) => {setFieldValue('isLiqueurs', val);}}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12}>
                            <Typography variant="h5">
                              <IconGlassCocktail />
                              {" Mixers"}
                            </Typography>
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12} md={6}>
                            <FormRatingControl
                              label="Bitters"
                              name="isBitters"
                              value={values.isBitters}
                              onChange={(e, val) => {setFieldValue('isBitters', val);}}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12} md={6}>
                            <FormRatingControl
                              label="SimpleSyrup"
                              name="isSimpleSyrup"
                              value={values.isSimpleSyrup}
                              onChange={(e, val) => {setFieldValue('isSimpleSyrup', val);}}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12} md={6}>
                            <FormRatingControl
                              label="Triple Sec"
                              name="isTripleSec"
                              value={values.isTripleSec}
                              onChange={(e, val) => {setFieldValue('isTripleSec', val);}}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12} md={6}>
                            <FormRatingControl
                              label="Grenadine"
                              name="isGrenadine"
                              value={values.isGrenadine}
                              onChange={(e, val) => {setFieldValue('isGrenadine', val);}}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12}>
                            <TextField
                              id="otherAlcoholicDrinks"
                              name="otherAlcoholicDrinks"
                              label={`Are there any other alcohols you would like us to provide?`}
                              value={values.otherAlcoholicDrinks}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.otherAlcoholicDrinks &&
                                Boolean(errors.otherAlcoholicDrinks)
                              }
                              helperText={
                                touched.otherAlcoholicDrinks &&
                                errors.otherAlcoholicDrinks
                              }
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12}>
                            <TextField
                              id="otherMixers"
                              name="otherMixers"
                              label={`Are there any other mixers you would like us to provide?`}
                              value={values.otherMixers}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.otherMixers &&
                                Boolean(errors.otherMixers)
                              }
                              helperText={
                                touched.otherMixers && errors.otherMixers
                              }
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        {!values.isNoAlchohol ? (
                          <Grid item xs={12}>
                            <TextField
                              id="drinkAndCocktailRequests"
                              name="drinkAndCocktailRequests"
                              label={`Are there any specific drinks or cocktails you would like us to make?`}
                              value={values.drinkAndCocktailRequests}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.drinkAndCocktailRequests &&
                                Boolean(errors.drinkAndCocktailRequests)
                              }
                              helperText={
                                touched.drinkAndCocktailRequests &&
                                errors.drinkAndCocktailRequests
                              }
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        <Grid item xs={12}>
                          <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            type="submit"
                            sx={{ mt: 2 }}
                          >
                            Next <ArrowRightAlt />
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
