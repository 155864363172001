import { Box, Grid } from "@mui/material";
import FormRatingControl from "./FormRatingControl";

export type ConditionalFormRatingControlProps = {
  isHidden: boolean;
  label: string;
  name: string;
  onChange: (event: React.SyntheticEvent, value: number | null) => void;
  value?: number;
};

export default function ConditionalFormRatingControl(
  props: ConditionalFormRatingControlProps
) {
  const { isHidden, label, name, onChange, value } = props;
  if (isHidden) {
    return <Box />;
  } else {
    return (
        <Grid item xs={12} md={6}>

      <FormRatingControl
        label={label}
        name={name}
        onChange={onChange}
        value={value}
      />
      </Grid>
    );
  }
}
