import { useRecoilState } from "recoil";
import { CurrentPassengerState } from "../recoil/PassengerAtom";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import OnboardingStepper from "../components/OnboardingStepper/OnboardingStepper";
import TripCard from "../components/TripOverview/TripCard";
import { Formik } from "formik";
import {
  GetDivingCertificationType,
  GetSwimmingLevel,
} from "../utils/enumUtils";
import { ArrowRightAlt } from "@mui/icons-material";
import { PassengerAquaticInfo } from "../types/passenger";
import { createAquaticInfo, getPassengerDetails } from "../services/passengerService";

export default function AquaticProficiency() {
  const [currentPassenger, setCurrentPassenger] = useRecoilState(
    CurrentPassengerState
  );
  const navigate = useNavigate();
  const initialValues = {
    swimmingLevel: 0,
    isCertifiedDiver: false,
    divingCertificationType: 0,
    certificationNumber: "",
    lastDiveDetails: "",
  };
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <OnboardingStepper activeStep={3} />
        </Grid>
        <Grid item xs={12}>
          <TripCard
            startDate={currentPassenger.trip?.startDate}
            startLocation={currentPassenger.trip?.startLocation}
            endDate={currentPassenger.trip?.endDate}
            endLocation={currentPassenger.trip?.endLocation}
            firstName={currentPassenger.firstName}
            lastName={currentPassenger.lastName}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 4,
              borderTopColor: "#b47f49",
              borderTopWidth: "4px",
              borderStyle: "solid",
              borderRightWidth: "0px",
              borderLeftWidth: "0px",
              borderBottomWidth: "0",
            }}
            elevation={12}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h4">
                  Swimming and Diving Proficiency
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Formik initialValues={initialValues} onSubmit={(values) => {
                  var aquaInfo: PassengerAquaticInfo = {
                    divingCertificationType: values.divingCertificationType,
                    lastDiveDetails: values.lastDiveDetails,
                    isCertifiedDiver: values.isCertifiedDiver,
                    swimmingLevel: values.swimmingLevel,
                    certificationNumber: values.certificationNumber
                  };
                  createAquaticInfo(currentPassenger.id, aquaInfo).then(
                    (tres) => {
                      getPassengerDetails(currentPassenger).then((res) => {
                        setCurrentPassenger(res.data);
                        navigate("/");
                      });
                    }
                  );
                }}>
                  {({
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <InputLabel id="swimmingLevel-label">
                            Rate your swimming level
                          </InputLabel>
                          <Select
                            labelId="swimmingLevel-label"
                            id="swimmingLevel"
                            required
                            value={values.swimmingLevel}
                            onChange={(e) => {
                              setFieldValue("swimmingLevel", e.target.value);
                            }}
                            fullWidth
                            sx={{ mb: 2 }}
                          >
                            <MenuItem value={0}>{GetSwimmingLevel(0)}</MenuItem>
                            <MenuItem value={1}>{GetSwimmingLevel(1)}</MenuItem>
                            <MenuItem value={2}>{GetSwimmingLevel(2)}</MenuItem>
                            <MenuItem value={3}>{GetSwimmingLevel(3)}</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={values.isCertifiedDiver} />
                            }
                            label="Are you a certified diver?"
                            name="isCertifiedDiver"
                            onChange={handleChange}
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        {values.isCertifiedDiver ? (
                          <Grid item xs={12}>
                            <InputLabel id="certType-label">
                              Certification Type
                            </InputLabel>
                            <Select
                              labelId="certType-label"
                              id="divingCertificationType"
                              required
                              value={values.divingCertificationType}
                              onChange={(e) => {
                                setFieldValue(
                                  "divingCertificationType",
                                  e.target.value
                                );
                              }}
                              fullWidth
                              sx={{ mb: 2 }}
                            >
                              <MenuItem value={0}>
                                {GetDivingCertificationType(0)}
                              </MenuItem>
                              <MenuItem value={1}>
                                {GetDivingCertificationType(1)}
                              </MenuItem>
                              <MenuItem value={2}>
                                {GetDivingCertificationType(2)}
                              </MenuItem>
                              <MenuItem value={3}>
                                {GetDivingCertificationType(3)}
                              </MenuItem>
                              <MenuItem value={4}>
                                {GetDivingCertificationType(4)}
                              </MenuItem>
                              <MenuItem value={5}>
                                {GetDivingCertificationType(5)}
                              </MenuItem>
                              <MenuItem value={6}>
                                {GetDivingCertificationType(6)}
                              </MenuItem>
                              <MenuItem value={7}>
                                {GetDivingCertificationType(7)}
                              </MenuItem>
                              <MenuItem value={8}>
                                {GetDivingCertificationType(8)}
                              </MenuItem>
                              <MenuItem value={9}>
                                {GetDivingCertificationType(9)}
                              </MenuItem>
                            </Select>
                          </Grid>
                        ) : (
                          <Box />
                        )}
                         <Grid item xs={12} md={6}>
                          {values.isCertifiedDiver ? (
                            <TextField
                              id="certificationNumber"
                              name="certificationNumber"
                              label={`Certification Number`}
                              value={values.certificationNumber}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.certificationNumber &&
                                Boolean(errors.certificationNumber)
                              }
                              helperText={
                                touched.certificationNumber && errors.certificationNumber
                              }
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                          ) : (
                            <Box />
                          )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          {values.isCertifiedDiver ? (
                            <TextField
                              id="lastDiveDetails"
                              name="lastDiveDetails"
                              label={`When and where was the last time you went on a dive?`}
                              value={values.lastDiveDetails}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.lastDiveDetails &&
                                Boolean(errors.lastDiveDetails)
                              }
                              helperText={
                                touched.lastDiveDetails && errors.lastDiveDetails
                              }
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                          ) : (
                            <Box />
                          )}
                          
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            type="submit"
                            sx={{ mt: 2 }}
                          >
                            Next <ArrowRightAlt />
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
