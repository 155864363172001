export function GetSwimmingLevel(swimmingLevel?: number) {
  switch (swimmingLevel) {
    case 0:
      return "I don't know how to swim.";
    case 1:
      return "Beginner";
    case 2:
      return "Intermediate";
    case 3:
      return "Advanced";
    default:
        return "Beginner";
  }
}

export function GetDivingCertificationType(divingCertNumber?: number) {
  switch (divingCertNumber) {
    case 0:
      return "PADI Scuba Diver";
    case 1:
      return "PADI Open Water Diver";
    case 2:
      return "PADI Advanced Open Water Diver";
    case 3:
      return "PADI Rescue Diver";
    case 4:
      return "PADI Master Scuba Diver";
    case 5:
      return "PADI Wreck Diver";
    case 6:
      return "PADI Enriched Air Diver";
    case 7:
      return "ACDE Commercial Diver";
    case 8:
      return "Divers Academy International Certificate of Completion";
    case 9:
      return "ADCI Commercial Diver";
    default:
      return "PADI Scuba Diver";
  }
}
