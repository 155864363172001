import { ArrowRightAlt, CalendarMonth, Sailing } from "@mui/icons-material";
import { Chip, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import moment from "moment";

export type TripCardProps = {
  startLocation?: string;
  endLocation?: string;
  startDate?: DateTime;
  endDate?: DateTime;
  firstName?: string;
  lastName?: string;
};

export default function TripCard(props: TripCardProps) {
  const {
    startLocation,
    startDate,
    endDate,
    endLocation,
    firstName,
    lastName,
  } = props;
  return (
    <Paper
      sx={{
        p: 1,
        borderTopColor: "#b47f49",
        borderTopWidth: "4px",
        borderStyle: "solid",
        borderRightWidth: "0px",
        borderLeftWidth: "0px",
        borderBottomWidth: "0",
      }}
      elevation={6}
    >
      <Grid container spacing={1}>
        <Grid item xs={1}>
          <Stack
            direction="column"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Sailing sx={{ fontSize: 44 }} />
          </Stack>
        </Grid>
        <Grid item xs={11}>
          <Grid container spacing={1}>
            <Grid item xs={12} textAlign="start">
              <Typography variant="h5">{`Welcome, ${firstName} ${lastName}!`}</Typography>
            </Grid>
            <Grid item xs={12} textAlign="start">
              <Typography variant="h5">
                Charter from {startLocation} to {endLocation}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item xs={12} md={2}>
                  <Chip
                    sx={{ p: 1 }}
                    icon={<CalendarMonth />}
                    label={moment.utc(startDate).local().format("MM/DD/YYYY")}
                  />
                </Grid>
                <Grid item xs={12} md={1}>
                  <ArrowRightAlt sx={{ fontSize: 32, ml: 1.5 }} />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Chip
                    sx={{ p: 1 }}
                    icon={<CalendarMonth />}
                    label={moment.utc(endDate).local().format("MM/DD/YYYY")}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
