import { atom } from "recoil";
import { Passenger } from "../types/passenger";

export const CurrentPassengerState = atom<Passenger>({
  key: "currentPassenger",
  default: {
    id: "",
    email: "",
    joinCode: "",
    onBoardingStatus: -1,
  },
});
