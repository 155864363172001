import "./App.css";
import { Box, CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { Route, Routes } from "react-router-dom";
import AccessPage from "./pages/AccessPage";
import { useRecoilState } from "recoil";
import { CurrentPassengerState } from "./recoil/PassengerAtom";
import ContactInfo from "./pages/ContactInfo";
import PassengerAppBar from "./components/AppBar/PassengerAppBar";
import { Scrollbar } from "react-scrollbars-custom";
import Dashboard from "./pages/Dashboard";
import TravelInfo from "./pages/TravelInfo";
import MedicalInfo from "./pages/MedicalInfo";
import AquaticProficiency from "./pages/AquaticProficiency";
import Activities from "./pages/Activities";
import DrinkPreferences from "./pages/DrinkPreferences";
import FoodPreferences from "./pages/FoodPreferences";

function App() {
  const [currentPassenger] = useRecoilState(CurrentPassengerState);
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary:{
        main: "#b47f49",
      },
      secondary:{
        main: "#7fdcfd",
      },
      background:{
        default: "#011828",
        paper: "#011828"
      },
    },
  });

  if (currentPassenger.id === "") {
    return (
      <ThemeProvider theme={darkTheme}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <CssBaseline />
          <AccessPage />
        </LocalizationProvider>
      </ThemeProvider>
    );
  }
  return (
    <ThemeProvider theme={darkTheme}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <CssBaseline />
        <Box
            sx={{ display: "flex", minHeight: "100dvh", minWidth: "100dvw" }}
          >
            <PassengerAppBar />
            <Scrollbar style={{ height: "100dvh" }}>
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  mt: "100px",
                }}
              >
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/:shipname/ContactInfo" element={<ContactInfo />} />
          <Route path="/:shipname/TravelInfo" element={<TravelInfo />} />
          <Route path="/:shipname/MedicalInfo" element={<MedicalInfo/>}/>
          <Route path="/:shipname/AquaticInfo" element={<AquaticProficiency/>}/>
          <Route path="/:shipname/Activities" element={<Activities/>}/>
          <Route path="/:shipname/Drinks" element={<DrinkPreferences/>}/>
          <Route path="/:shipname/Food" element={<FoodPreferences/>}/>
        </Routes>
        </Box></Scrollbar></Box>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
