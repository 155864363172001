import { ArrowRightAlt } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Input,
  LinearProgress,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Formik } from "formik";
import { DateTime } from "luxon";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import OnboardingStepper from "../components/OnboardingStepper/OnboardingStepper";
import TripCard from "../components/TripOverview/TripCard";
import { CurrentPassengerState } from "../recoil/PassengerAtom";
import {
  createTravelInfo,
  getPassengerDetails,
  uploadPassportFile,
} from "../services/passengerService";
import { PassengerTravelInfo } from "../types/passenger";

export default function TravelInfo() {
  const [currentPassenger, setCurrentPassenger] = useRecoilState(
    CurrentPassengerState
  );
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [file, setFile] = useState<any>(null);
  const navigate = useNavigate();

  var initialValues = {
    arrivalFlightTime: DateTime.now().plus({ days: 1 }),
    arrivalFlightNumber: "",
    arrivalFlightAirport: "",
    arrivalTransportationRequired: false,
    returnFlightTime: DateTime.now().plus({ days: 1 }),
    returnFlightNumber: "",
    returnFlightAirport: "",
    returnTransportationRequired: false,
  };
  function handleFileChange(event: any) {
    setFile(event.target.files[0]);
  }

  const onUploadProgress = (progressevent: ProgressEvent) => {
    const percentCompleted = Math.round(
      (progressevent.loaded * 100) / progressevent.total
    );
    setUploadProgress(percentCompleted);
    if (percentCompleted === 100) {
      setUploading(false);
    }
  };

  return (
    <Container>
      <Modal
        open={uploadOpen}
        onClose={() => {
          setUploadOpen(false);
          setUploading(false);
        }}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Card sx={{ mb: 4 }}>
            <CardContent sx={{ background: "rgba(255, 255, 255, 0.12)" }}>
              {!uploading ? (
                <Box />
              ) : (
                <Box>
                  <Typography variant="h5" sx={{ mb: 2 }}>
                    Uploading...{" "}
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={uploadProgress}
                  />
                </Box>
              )}
            </CardContent>
          </Card>
        </Box>
      </Modal>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <OnboardingStepper activeStep={1} />
        </Grid>
        <Grid item xs={12}>
          <TripCard
            startDate={currentPassenger.trip?.startDate}
            startLocation={currentPassenger.trip?.startLocation}
            endDate={currentPassenger.trip?.endDate}
            endLocation={currentPassenger.trip?.endLocation}
            firstName={currentPassenger.firstName}
            lastName={currentPassenger.lastName}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 4,
              borderTopColor: "#b47f49",
              borderTopWidth: "4px",
              borderStyle: "solid",
              borderRightWidth: "0px",
              borderLeftWidth: "0px",
              borderBottomWidth: "0",
            }}
            elevation={12}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h4">Travel Information</Typography>
              </Grid>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Formik
                  initialValues={initialValues}
                  onSubmit={(values) => {
                    var travelinfo: PassengerTravelInfo = {
                      arrivalFlightAirport: values.arrivalFlightAirport,
                      arrivalFlightNumber: values.arrivalFlightNumber,
                      arrivalFlightTime: values.arrivalFlightTime,
                      arrivalTransportationRequired:
                        values.arrivalTransportationRequired,
                      returnFlightAirport: values.returnFlightAirport,
                      returnFlightNumber: values.returnFlightNumber,
                      returnFlightTime: values.returnFlightTime,
                      returnTransportationRequired:
                        values.returnTransportationRequired,
                    };
                    createTravelInfo(currentPassenger.id, travelinfo).then(
                      (tres) => {
                        setUploadOpen(true);
                        const formData = new FormData();
                        formData.append("file", file);
                        formData.append("fileName", file.Name);
                        setUploading(true);
                        uploadPassportFile(
                          onUploadProgress,
                          currentPassenger.id,
                          tres,
                          formData
                        ).then((res) => {
                          getPassengerDetails(currentPassenger).then((res) => {
                            setUploadOpen(false);
                            setUploadProgress(0);
                            setUploading(false);
                            setFile(null);
                            setCurrentPassenger(res.data);
                            navigate("/");
                          });
                        });
                      }
                    );
                  }}
                >
                  {({
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Stack direction="column">
                            <Typography variant="h6">Passport</Typography>
                            <Typography variant="body2">
                              Please upload a photo of your passport.
                            </Typography>
                            <Input
                              sx={{ mb: 2 }}
                              type="file"
                              inputProps={{ accept: "image/png, image/jpeg" }}
                              onChange={handleFileChange}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          <Stack direction="column">
                            <Typography variant="h6">Arrival Flight</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Stack>
                            <DateTimePicker
                              disablePast
                              label="Flight Time"
                              value={values.arrivalFlightTime}
                              onChange={(value: any) =>
                                setFieldValue(
                                  "arrivalFlightTime",
                                  DateTime.fromISO(value),
                                  true
                                )
                              }
                              sx={{ mb: 2 }}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id="arrivalFlightNumber"
                            name="arrivalFlightNumber"
                            label={`Flight Number`}
                            value={values.arrivalFlightNumber}
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.arrivalFlightNumber &&
                              Boolean(errors.arrivalFlightNumber)
                            }
                            helperText={
                              touched.arrivalFlightNumber &&
                              errors.arrivalFlightNumber
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id="arrivalFlightAirport"
                            name="arrivalFlightAirport"
                            label={`Airport`}
                            value={values.arrivalFlightAirport}
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.arrivalFlightAirport &&
                              Boolean(errors.arrivalFlightAirport)
                            }
                            helperText={
                              touched.arrivalFlightAirport &&
                              errors.arrivalFlightAirport
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.arrivalTransportationRequired}
                              />
                            }
                            label="Do you need us to arrange transportation for your arrival?"
                            name="arrivalTransportationRequired"
                            onChange={handleChange}
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          <Stack direction="column">
                            <Typography variant="h6">Return Flight</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Stack>
                            <DateTimePicker
                              disablePast
                              label="Flight Time"
                              value={values.returnFlightTime}
                              onChange={(value: any) =>
                                setFieldValue(
                                  "returnFlightTime",
                                  DateTime.fromISO(value),
                                  true
                                )
                              }
                              sx={{ mb: 2 }}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id="returnFlightNumber"
                            name="returnFlightNumber"
                            label={`Flight Number`}
                            value={values.returnFlightNumber}
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.returnFlightNumber &&
                              Boolean(errors.returnFlightNumber)
                            }
                            helperText={
                              touched.returnFlightNumber &&
                              errors.returnFlightNumber
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id="returnFlightAirport"
                            name="returnFlightAirport"
                            label={`Airport`}
                            value={values.returnFlightAirport}
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.returnFlightAirport &&
                              Boolean(errors.returnFlightAirport)
                            }
                            helperText={
                              touched.returnFlightAirport &&
                              errors.returnFlightAirport
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.returnTransportationRequired}
                              />
                            }
                            label="Do you need us to arrange transportation for your return?"
                            name="returnTransportationRequired"
                            onChange={handleChange}
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {file === undefined || file === null ? (
                            <Button
                              color="primary"
                              variant="contained"
                              fullWidth
                              type="submit"
                              disabled
                              sx={{ mt: 2 }}
                            >
                              Next <ArrowRightAlt />
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              variant="contained"
                              fullWidth
                              type="submit"
                              sx={{ mt: 2 }}
                            >
                              Next <ArrowRightAlt />
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
