import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { Formik } from "formik";
import { Passenger } from "../types/passenger";
import { getPassengerDetails } from "../services/passengerService";
import { useSetRecoilState } from "recoil";
import { CurrentPassengerState } from "../recoil/PassengerAtom";

export default function AccessPage() {
  const setCurrentPassenger = useSetRecoilState(CurrentPassengerState);
  const validationSchema = yup.object({
    email: yup.string().required("Email Address is required."),
    joinCode: yup
      .string()
      .required(
        "Join Code is required. Please check your inbox for the welcome email."
      ),
  });
  const initialState = {
    email: "",
    joinCode: "",
  };

  return (
    <Container
      sx={{
        height: "100dvh",
      }}
    >
      <Box
        display="flex"
        height="100%"
        sx={{
          width:
            "clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)",
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Paper
          sx={{
            p: 4,
            borderTopColor: "#b47f49",
            borderTopWidth: "4px",
            borderStyle: "solid",
            borderRightWidth: "0",
            borderLeftWidth: "0",
            borderBottomWidth: "0",
            width: "100%",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} textAlign="center">
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Avatar
                  alt="Zeafarer Logo"
                  src={require("../assets/Zsail.png")}
                  sx={{ width: 56, height: 56 }}
                />
                <Typography variant="h3">Welcome to ZeaFarer</Typography>
                <Typography variant="body1">
                  {
                    "Please enter your email and the unique join code sent to that email to begin."
                  }
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  var passengerRequest: Passenger = {
                    email: values.email,
                    joinCode: values.joinCode,
                  };
                  getPassengerDetails(passengerRequest).then((res) => {
                    if (res.status === 404) {
                    } else if (res.status === 400) {
                    } else {
                      setCurrentPassenger(res.data);
                    }
                  });
                }}
              >
                {({
                  errors,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  touched,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      id="email"
                      name="email"
                      label={`Email Address`}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      fullWidth
                      sx={{ mb: 2 }}
                    />
                    <TextField
                      id="joinCode"
                      name="joinCode"
                      label={`Join Code`}
                      value={values.joinCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.joinCode && Boolean(errors.joinCode)}
                      helperText={touched.joinCode && errors.joinCode}
                      fullWidth
                      sx={{ mb: 2 }}
                    />
                    <Button
                      sx={{ mt: 2 }}
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="submit"
                    >
                      Login
                    </Button>
                  </form>
                )}
              </Formik>
            </Grid>
            <Grid item xs={12}>
              <Box textAlign={"center"} justifyContent={"center"}>
                By signing in, you are agreeing to Zeafarer's{" "}
                <a
                  style={{color: "#b47f49"}}
                  href="https://cdn.zeafarer.io/zsailassets/Privacy%20Policy.pdf"
                  rel="noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a
                  style={{color: "#b47f49"}}
                  rel="noreferrer"
                  href="https://cdn.zeafarer.io/zsailassets/Zeafarer%20Terms%20of%20Service.pdf"
                >
                  Terms of Service
                </a>{" "}
                © ZeaFarer {new Date().getFullYear()}
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Container>
  );
}
