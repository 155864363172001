import {
    Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import OnboardingStepper from "../components/OnboardingStepper/OnboardingStepper";
import TripCard from "../components/TripOverview/TripCard";
import { Formik } from "formik";
import { CurrentPassengerState } from "../recoil/PassengerAtom";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import {  IconBeach, IconPawFilled, IconSwimming } from "@tabler/icons-react";
import { ArrowRightAlt } from "@mui/icons-material";
import { PassengerActivityInfo } from "../types/passenger";
import { createActivityInfo, getPassengerDetails } from "../services/passengerService";

export default function Activities() {
  const [currentPassenger, setCurrentPassenger] = useRecoilState(
    CurrentPassengerState
  );
  const navigate = useNavigate();
  var initialValues = {
    isWildlifeSighting: 0,
    isWildlifeBird: false,
    isWildlifeFish: false,
    isWildlifeSeaMammals: false,
    isWildlifeAmphibiansAndLizards: false,
    wildlifeOther: "",
    isWaterBeachCombing: false,
    isWaterFishing: false,
    isWaterSailing: false,
    isWaterScubaDiving: false,
    isWaterSnorkeling: false,
    isWaterSunbathing: false,
    isWaterSurfing: false,
    isWaterSwimming: false,
    isIslandBars: false,
    isIslandCycling: false,
    isIslandDining: false,
    isIslandGolf: false,
    isIslandHiking: false,
    isIslandTours: false,
    isIslandKaraoke: false,
    isIslandMuseumsHeritage: false,
    isIslandMusicDancing: false,
    isIslandNightClubs: false,
    isIslandShopping: false,
    isIslandSportingEvents: false,
    passengerEquipment: "",
  };
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <OnboardingStepper activeStep={4} />
        </Grid>
        <Grid item xs={12}>
          <TripCard
            startDate={currentPassenger.trip?.startDate}
            startLocation={currentPassenger.trip?.startLocation}
            endDate={currentPassenger.trip?.endDate}
            endLocation={currentPassenger.trip?.endLocation}
            firstName={currentPassenger.firstName}
            lastName={currentPassenger.lastName}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 4,
              borderTopColor: "#b47f49",
              borderTopWidth: "4px",
              borderStyle: "solid",
              borderRightWidth: "0px",
              borderLeftWidth: "0px",
              borderBottomWidth: "0",
            }}
            elevation={12}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h4">Activities of Interest</Typography>
              </Grid>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Formik initialValues={initialValues} onSubmit={(values) => {
                    var activity: PassengerActivityInfo = {
                        isWildlifeSighting: values.isWildlifeSighting,
                        isWildlifeBird: values.isWildlifeBird,
                        isWildlifeFish: values.isWildlifeFish,
                        isWildlifeSeaMammals: values.isWildlifeSeaMammals,
                        isWildlifeAmphibiansAndLizards: values.isWildlifeAmphibiansAndLizards,
                        wildlifeOther: values.wildlifeOther,
                        isWaterBeachCombing: values.isWaterBeachCombing,
                        isWaterFishing: values.isWaterFishing,
                        isWaterSailing: values.isWaterSailing,
                        isWaterScubaDiving: values.isWaterScubaDiving,
                        isWaterSnorkeling: values.isWaterSnorkeling,
                        isWaterSunbathing: values.isWaterSnorkeling,
                        isWaterSurfing: values.isWaterSurfing,
                        isWaterSwimming: values.isWaterSwimming,
                        isIslandBars: values.isIslandBars,
                        isIslandCycling: values.isIslandCycling,
                        isIslandDining: values.isIslandDining,
                        isIslandGolf: values.isIslandGolf,
                        isIslandHiking: values.isIslandHiking,
                        isIslandTours: values.isIslandTours,
                        isIslandKaraoke: values.isIslandKaraoke,
                        isIslandMuseumsHeritage: values.isIslandMuseumsHeritage,
                        isIslandMusicDancing: values.isIslandMusicDancing,
                        isIslandNightClubs: values.isIslandNightClubs,
                        isIslandShopping: values.isIslandShopping,
                        isIslandSportingEvents: values.isIslandSportingEvents,
                        passengerEquipment: values.passengerEquipment,
                    };
                    createActivityInfo(currentPassenger.id, activity).then(
                        (tres) => {
                          getPassengerDetails(currentPassenger).then((res) => {
                            setCurrentPassenger(res.data);
                            navigate("/");
                          });
                        }
                      );
                }}>
                  {({
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h5">
                            <IconPawFilled /> Wildlife
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <InputLabel id="wildlifelabel">
                            Your Response to a wildlife sighting
                          </InputLabel>
                          <Select
                            labelId="wildlifelabel"
                            id="isWildlifeSighting"
                            required
                            value={values.isWildlifeSighting}
                            onChange={(e) => {
                              setFieldValue(
                                "isWildlifeSighting",
                                e.target.value
                              );
                            }}
                            fullWidth
                            sx={{ mb: 2 }}
                          >
                            <MenuItem value={1}>
                              {
                                "Wake me up, I am dying to see wildlife and I can sleep later!"
                              }
                            </MenuItem>
                            <MenuItem value={0}>
                              {
                                "I’m on vacation to sleep, I’ll see wildlife if I’m around."
                              }
                            </MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                              {
                                "Tell us your favorite types of wildlife and we’ll try to point them out if we can. (Note the specific animals we find may vary depending on Charter location and time of year)"
                              }
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={values.isWildlifeBird} />
                            }
                            label="Birds (Seahawks, Finches, Puffins, Pelicans, Penguins, etc)"
                            name="isWildlifeBird"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={values.isWildlifeFish} />
                            }
                            label="Fish (Tuna, Barracudas, Schools of Fish, etc)"
                            name="isWildlifeFish"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={values.isWildlifeSeaMammals} />
                            }
                            label="Sea Mammals (Seals, Walruses, Otters, Whales, Dolphins, etc)"
                            name="isWildlifeSeaMammals"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={values.isWildlifeAmphibiansAndLizards} />
                            }
                            label="Amphibians and Lizards (Snakes, Turtles, etc)"
                            name="isWildlifeAmphibiansAndLizards"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="wildlifeOther"
                            name="wildlifeOther"
                            label={`Other`}
                            value={values.wildlifeOther}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.wildlifeOther &&
                              Boolean(errors.wildlifeOther)
                            }
                            helperText={
                              touched.wildlifeOther &&
                              errors.wildlifeOther
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">Tell us what other activities you are interested in:</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5"><IconSwimming/> Water Activities</Typography>
                        </Grid>
                        <Grid item xs={12}md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={values.isWaterBeachCombing} />
                            }
                            label="Beach Combing"
                            name="isWaterBeachCombing"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={values.isWaterFishing} />
                            }
                            label="Fishing*"
                            name="isWaterFishing"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={values.isWaterSailing} />
                            }
                            label="Sailing"
                            name="isWaterSailing"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={values.isWaterScubaDiving} />
                            }
                            label="Scuba Diving**"
                            name="isWaterScubaDiving"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={values.isWaterSnorkeling} />
                            }
                            label="Snorkeling"
                            name="isWaterSnorkeling"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={values.isWaterSunbathing} />
                            }
                            label="Sunbathing"
                            name="isWaterSunbathing"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={values.isWaterSurfing} />
                            }
                            label="Surfing"
                            name="isWaterSurfing"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={values.isWaterSwimming} />
                            }
                            label="Swimming"
                            name="isWaterSwimming"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body2">{"*Fishing License may be required in some locations"}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body2">{"**The ship has its own Scuba Equipment and Diving will be conducted on board if available or we will arrange rendezvous diving if necessary."}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5"><IconBeach/> Island Activities</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={values.isIslandBars} />
                            }
                            label="Bars"
                            name="isIslandBars"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={values.isIslandCycling} />
                            }
                            label="Cycling"
                            name="isIslandCycling"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={values.isIslandDining} />
                            }
                            label="Dining Ashore"
                            name="isIslandDining"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={values.isIslandGolf} />
                            }
                            label="Golf*"
                            name="isIslandGolf"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={values.isIslandHiking} />
                            }
                            label="Hiking"
                            name="isIslandHiking"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={values.isIslandTours} />
                            }
                            label="Island Tours"
                            name="isIslandTours"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={values.isIslandKaraoke} />
                            }
                            label="Karaoke"
                            name="isIslandKaraoke"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={values.isIslandMuseumsHeritage} />
                            }
                            label="Museums and Cultural Heritage Sites"
                            name="isIslandMuseumsHeritage"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={values.isIslandMusicDancing} />
                            }
                            label="Music/Dancing"
                            name="isIslandMusicDancing"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={values.isIslandNightClubs} />
                            }
                            label="Night Clubs"
                            name="isIslandNightClubs"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={values.isIslandShopping} />
                            }
                            label="Shopping"
                            name="isIslandShopping"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={values.isIslandSportingEvents} />
                            }
                            label="Sporting Events"
                            name="isIslandSportingEvents"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body2">{"*We would check availability of location and dates."}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider/>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="passengerEquipment"
                            name="passengerEquipment"
                            label={`Do you plan on bringing any specialized equipment for activities during your trip?`}
                            value={values.passengerEquipment}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.passengerEquipment &&
                              Boolean(errors.passengerEquipment)
                            }
                            helperText={
                              touched.passengerEquipment &&
                              errors.passengerEquipment
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            type="submit"
                            sx={{ mt: 2 }}
                          >
                            Next <ArrowRightAlt />
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
