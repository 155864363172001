import { Grid, Rating, Typography } from "@mui/material";

export type FormRatingControlProps = {
  label: string;
  name: string;
  onChange: (event: React.SyntheticEvent, value: number | null) => void;
  value?: number;
};
export default function FormRatingControl(props: FormRatingControlProps) {
  const { label, name, onChange, value } = props;
  return (
    <Grid container>
      <Grid item xs={3} alignItems="center">
        <Typography variant="body1">{label}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Rating name={name} onChange={onChange} sx={{ ml: 2 }} value={value} />
      </Grid>
    </Grid>
  ); 
}
