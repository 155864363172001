import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { CurrentPassengerState } from "../recoil/PassengerAtom";
import { useNavigate } from "react-router-dom";
import OnboardingStepper from "../components/OnboardingStepper/OnboardingStepper";
import TripCard from "../components/TripOverview/TripCard";
import { Formik } from "formik";
import { Star } from "@mui/icons-material";
import {
  IconCheese,
  IconCherry,
  IconCookie,
  IconFish,
  IconMeat,
  IconSalt,
  IconSeeding,
  IconSunrise,
  IconToolsKitchen2,
} from "@tabler/icons-react";
import FormRatingControl from "../components/FormRatingControl/FormRatingControl";
import ConditionalFormRatingControl from "../components/FormRatingControl/ConditionalFormRatingControl";
import { PassengerFoodInformation } from "../types/food";
import { createFoodInfo, getPassengerDetails } from "../services/passengerService";

export default function FoodPreferences() {
  const [currentPassenger, setCurrentPassenger] = useRecoilState(
    CurrentPassengerState
  );
  const navigate = useNavigate();
  var initialValues = {
    otherDishes: "",
    isVegetarian: false,
    isVegan: false,
    isEggDairy: false,
    dietaryRestrictions: "",
    pancakes: 3,
    waffles: 3,
    toast: 3,
    muffins: 3,
    pastries: 3,
    fruit: 3,
    hardCheese: 3,
    eggs: 3,
    bacon: 3,
    breakfastSausage: 3,
    omelet: 3,
    cereal: 3,
    biscuits: 3,
    yogurt: 3,
    breakfastOther: "",
    blueCheese: 3,
    cheddar: 3,
    provolone: 3,
    swiss: 3,
    american: 3,
    brie: 3,
    feta: 3,
    goat: 3,
    colby: 3,
    parmesan: 3,
    cheeseOther: "",
    cookie: 3,
    cake: 3,
    dessertPastries: 3,
    iceCream: 3,
    donuts: 3,
    brownies: 3,
    confectionery: 3,
    dessertOther: "",
    apples: 3,
    bananas: 3,
    oranges: 3,
    grapes: 3,
    melons: 3,
    strawberries: 3,
    blueBerries: 3,
    raspberries: 3,
    peaches: 3,
    plums: 3,
    pineapple: 3,
    fruitOther: "",
    beef: 3,
    chicken: 3,
    pork: 3,
    sausage: 3,
    lamb: 3,
    turkey: 3,
    meatOther: "",
    ginger: 3,
    garlic: 3,
    onion: 3,
    oregano: 3,
    thyme: 3,
    rosemary: 3,
    paprika: 3,
    blackPepper: 3,
    soySauce: 3,
    aOneSauce: 3,
    salt: 3,
    cumin: 3,
    barbecueSauce: 3,
    chipotle: 3,
    chiliPowder: 3,
    cayanne: 3,
    nutmeg: 3,
    cilantro: 3,
    teriyaki: 3,
    ranch: 3,
    ketchup: 3,
    mustard: 3,
    otherSauce: "",
    ceviche: 3,
    sushi: 3,
    cod: 3,
    mahiMahi: 3,
    salmon: 3,
    seaBass: 3,
    snapper: 3,
    swordfish: 3,
    tuna: 3,
    shrimp: 3,
    lobster: 3,
    oysters: 3,
    clams: 3,
    mussels: 3,
    calamari: 3,
    octopus: 3,
    seafoodOther: "",
    curry: 3,
    hotSoup: 3,
    coldSoup: 3,
    rice: 3,
    pasta: 3,
    bread: 3,
    risotto: 3,
    pastaOther: "",
    asparagus: 3,
    avocado: 3,
    beets: 3,
    bellPeppers: 3,
    hotPeppers: 3,
    broccoli: 3,
    blackBeans: 3,
    pintoBeans: 3,
    garbanzoBeans: 3,
    otherBeans: 3,
    cauliflower: 3,
    cabbage: 3,
    cucumbers: 3,
    carrots: 3,
    corn: 3,
    eggplant: 3,
    lettuce: 3,
    onions: 3,
    mashedPotatoes: 3,
    friedPotatoes: 3,
    bakedPotatoes: 3,
    mashedSweetPotatoes: 3,
    friedSweetPotatoes: 3,
    bakedSweetPotatoes: 3,
    spinach: 3,
    squash: 3,
    tomatoes: 3,
    zucchini: 3,
    vegetableOther: "",
  };
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <OnboardingStepper activeStep={6} />
        </Grid>
        <Grid item xs={12}>
          <TripCard
            startDate={currentPassenger.trip?.startDate}
            startLocation={currentPassenger.trip?.startLocation}
            endDate={currentPassenger.trip?.endDate}
            endLocation={currentPassenger.trip?.endLocation}
            firstName={currentPassenger.firstName}
            lastName={currentPassenger.lastName}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 4,
              borderTopColor: "#b47f49",
              borderTopWidth: "4px",
              borderStyle: "solid",
              borderRightWidth: "0px",
              borderLeftWidth: "0px",
              borderBottomWidth: "0",
            }}
            elevation={12}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h4">Food Preferences</Typography>
              </Grid>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Formik
                  initialValues={initialValues}
                  onSubmit={(values) => {
                    var foodInformation: PassengerFoodInformation = {
                      isVegan: values.isVegan,
                      isVegetarian: values.isVegetarian,
                      dietaryRestrictions: values.dietaryRestrictions,
                      isEggDairy: values.isEggDairy,
                      otherDishes: values.otherDishes,
                      breakfastInformation: {
                        pancakes: values.pancakes,
                        waffles: values.waffles,
                        toast: values.toast,
                        muffins: values.muffins,
                        pastries: values.pastries,
                        fruit: values.fruit,
                        hardCheese: values.hardCheese,
                        eggs: values.eggs,
                        bacon: values.bacon,
                        sausage: values.breakfastSausage,
                        omelet: values.omelet,
                        cereal: values.cereal,
                        biscuits: values.biscuits,
                        yogurt: values.yogurt,
                        other: values.breakfastOther,
                      },
                      cheeseInformation: {
                        blueCheese: values.blueCheese,
                        cheddar: values.cheddar,
                        provolone: values.provolone,
                        swiss: values.swiss,
                        american: values.american,
                        brie: values.brie,
                        feta: values.feta,
                        goat: values.goat,
                        colby: values.colby,
                        parmesan: values.parmesan,
                        other: values.cheeseOther,
                      },
                      dessertInformation: {
                        cookie: values.cookie,
                        cake: values.cake,
                        pastries: values.dessertPastries,
                        iceCream: values.iceCream,
                        donuts: values.donuts,
                        brownies: values.brownies,
                        confectionery: values.confectionery,
                        other: values.dessertOther,
                      },
                      fruitInformation: {
                        apples: values.apples,
                        bananas: values.bananas,
                        oranges: values.oranges,
                        grapes: values.grapes,
                        melons: values.melons,
                        strawberries: values.strawberries,
                        blueBerries: values.blueBerries,
                        raspberries: values.raspberries,
                        peaches: values.peaches,
                        plums: values.plums,
                        pineapple: values.pineapple,
                        other: values.fruitOther,
                      },
                      meatInformation: {
                        beef: values.beef,
                        chicken: values.chicken,
                        pork: values.pork,
                        sausage: values.sausage,
                        lamb: values.lamb,
                        turkey: values.turkey,
                        other: values.meatOther,
                      },
                      sauceSpiceInformation: {
                        ginger: values.ginger,
                        garlic: values.garlic,
                        onion: values.onion,
                        oregano: values.oregano,
                        thyme: values.thyme,
                        rosemary: values.rosemary,
                        paprika: values.paprika,
                        blackPepper: values.blackPepper,
                        soySauce: values.soySauce,
                        aOneSauce: values.aOneSauce,
                        salt: values.salt,
                        cumin: values.cumin,
                        barbecueSauce: values.barbecueSauce,
                        chipotle: values.chipotle,
                        chiliPowder: values.chiliPowder,
                        cayanne: values.cayanne,
                        nutmeg: values.nutmeg,
                        cilantro: values.cilantro,
                        teriyaki: values.teriyaki,
                        ranch: values.ranch,
                        ketchup: values.ketchup,
                        mustard: values.mustard,
                        other: values.otherSauce,
                      },
                      seafoodInformation: {
                        ceviche: values.ceviche,
                        sushi: values.sushi,
                        cod: values.cod,
                        mahiMahi: values.mahiMahi,
                        salmon: values.salmon,
                        seaBass: values.seaBass,
                        snapper: values.snapper,
                        swordfish: values.swordfish,
                        tuna: values.tuna,
                        shrimp: values.shrimp,
                        lobster: values.lobster,
                        oysters: values.oysters,
                        clams: values.clams,
                        mussels: values.mussels,
                        calamari: values.calamari,
                        octopus: values.octopus,
                        other: values.seafoodOther
                      },
                      soupRiceInformation: {
                        curry: values.curry,
                        hotSoup: values.hotSoup,
                        coldSoup: values.coldSoup,
                        pasta: values.pasta,
                        rice: values.rice,
                        bread: values.bread,
                        risotto: values.risotto,
                        other: values.pastaOther
                      },
                      vegetablesInformation: {
                        asparagus: values.asparagus,
                        avocado: values.avocado,
                        beets:values.beets,
                        bellPeppers: values.bellPeppers,
                        hotPeppers: values.hotPeppers,
                        broccoli: values.broccoli,
                        blackBeans: values.blackBeans,
                        pintoBeans: values.pintoBeans,
                        garbanzoBeans: values.garbanzoBeans,
                        otherBeans: values.otherBeans,
                        cauliflower: values.cauliflower,
                        cabbage: values.cabbage,
                        cucumbers: values.cucumbers,
                        carrots: values.carrots,
                        corn: values.corn,
                        eggplant: values.eggplant,
                        lettuce: values.lettuce,
                        onions: values.onions,
                        mashedPotatoes: values.mashedPotatoes, 
                        friedPotatoes: values.friedPotatoes,
                        bakedPotatoes: values.bakedPotatoes,
                        mashedSweetPotatoes: values.mashedSweetPotatoes,
                        friedSweetPotatoes: values.friedSweetPotatoes,
                        bakedSweetPotatoes: values.bakedSweetPotatoes,
                        spinach: values.spinach,
                        tomatoes: values.tomatoes,
                        zucchini: values.zucchini,
                        squash: values.squash,
                        other: values.vegetableOther
                      }
                    };
                    createFoodInfo(currentPassenger.id, foodInformation).then((dres) =>
                      getPassengerDetails(currentPassenger).then((res) => {
                        setCurrentPassenger(res.data);
                        navigate("/");
                      })
                    );
                  }}
                >
                  {({
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={<Checkbox checked={values.isVegetarian} />}
                            label="Are you vegetarian?"
                            name="isVegetarian"
                            onChange={handleChange}
                          />
                        </Grid>
                        {values.isVegetarian ? (
                          <Grid item xs={12} md={6}>
                            <FormControlLabel
                              control={<Checkbox checked={values.isEggDairy} />}
                              label="Do you eat Eggs or Dairy?"
                              name="isEggDairy"
                              onChange={handleChange}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={<Checkbox checked={values.isVegan} />}
                            label="Are you vegan?"
                            name="isVegan"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="dietaryRestrictions"
                            name="dietaryRestrictions"
                            label={`Do you have any other dietary restrictions we should know about?`}
                            value={values.dietaryRestrictions}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.dietaryRestrictions &&
                              Boolean(errors.dietaryRestrictions)
                            }
                            helperText={
                              touched.dietaryRestrictions &&
                              errors.dietaryRestrictions
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          <Alert severity="info" variant="outlined">
                            <Stack direction="column">
                              <Typography variant="body1">
                                Please rate the following food preferences. We
                                will do our best to accommodate everyone’s
                                preferences, but please understand that some
                                items may not always be available.
                              </Typography>
                              <List>
                                <ListItem>
                                  1<Star /> - Cannot Have
                                </ListItem>
                                <ListItem>
                                  2<Star />- Dislike
                                </ListItem>
                                <ListItem>
                                  3<Star /> - Neutral
                                </ListItem>
                                <ListItem>
                                  4<Star /> - Like
                                </ListItem>
                                <ListItem>
                                  5<Star /> - Must Have
                                </ListItem>
                              </List>
                            </Stack>
                          </Alert>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h4">
                            <IconCherry /> Fruit
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Apples"
                            name="apples"
                            value={values.apples}
                            onChange={(e, val) => {
                              setFieldValue("apples", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Bananas"
                            name="bananas"
                            value={values.bananas}
                            onChange={(e, val) => {
                              setFieldValue("bananas", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Oranges"
                            name="oranges"
                            value={values.oranges}
                            onChange={(e, val) => {
                              setFieldValue("oranges", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Grapes"
                            name="grapes"
                            value={values.grapes}
                            onChange={(e, val) => {
                              setFieldValue("grapes", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Melons"
                            name="melons"
                            value={values.melons}
                            onChange={(e, val) => {
                              setFieldValue("melons", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Strawberries"
                            name="strawberries"
                            value={values.strawberries}
                            onChange={(e, val) => {
                              setFieldValue("strawberries", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Blueberries"
                            name="blueBerries"
                            value={values.blueBerries}
                            onChange={(e, val) => {
                              setFieldValue("blueBerries", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Raspberries"
                            name="raspberries"
                            value={values.raspberries}
                            onChange={(e, val) => {
                              setFieldValue("raspberries", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Peaches"
                            name="peaches"
                            value={values.peaches}
                            onChange={(e, val) => {
                              setFieldValue("peaches", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Plums"
                            name="plums"
                            value={values.plums}
                            onChange={(e, val) => {
                              setFieldValue("plums", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Pineapple"
                            name="pineapple"
                            value={values.pineapple}
                            onChange={(e, val) => {
                              setFieldValue("pineapple", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="fruitOther"
                            name="fruitOther"
                            label={`Are there any specific Fruits you would like us to get?`}
                            value={values.fruitOther}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.fruitOther && Boolean(errors.fruitOther)
                            }
                            helperText={touched.fruitOther && errors.fruitOther}
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h4">
                            <IconSeeding /> Vegetables
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Asparagus"
                            name="asparagus"
                            value={values.asparagus}
                            onChange={(e, val) => {
                              setFieldValue("asparagus", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Avocado"
                            name="avocado"
                            value={values.avocado}
                            onChange={(e, val) => {
                              setFieldValue("avocado", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Beets"
                            name="beets"
                            value={values.beets}
                            onChange={(e, val) => {
                              setFieldValue("beets", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Bell Peppers"
                            name="bellPeppers"
                            value={values.bellPeppers}
                            onChange={(e, val) => {
                              setFieldValue("bellPeppers", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Hot Peppers"
                            name="hotPeppers"
                            value={values.hotPeppers}
                            onChange={(e, val) => {
                              setFieldValue("hotPeppers", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Broccoli"
                            name="broccoli"
                            value={values.broccoli}
                            onChange={(e, val) => {
                              setFieldValue("broccoli", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Black Beans"
                            name="blackBeans"
                            value={values.blackBeans}
                            onChange={(e, val) => {
                              setFieldValue("blackBeans", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Pinto Beans"
                            name="pintoBeans"
                            value={values.pintoBeans}
                            onChange={(e, val) => {
                              setFieldValue("pintoBeans", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Garbanzo Beans"
                            name="garbanzoBeans"
                            value={values.garbanzoBeans}
                            onChange={(e, val) => {
                              setFieldValue("garbanzoBeans", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Other Beans"
                            name="otherBeans"
                            value={values.otherBeans}
                            onChange={(e, val) => {
                              setFieldValue("otherBeans", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Cauliflower"
                            name="cauliflower"
                            value={values.cauliflower}
                            onChange={(e, val) => {
                              setFieldValue("cauliflower", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Cabbage"
                            name="cabbage"
                            value={values.cabbage}
                            onChange={(e, val) => {
                              setFieldValue("cabbage", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Cucumbers"
                            name="cucumbers"
                            value={values.cucumbers}
                            onChange={(e, val) => {
                              setFieldValue("cucumbers", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Carrots"
                            name="carrots"
                            value={values.carrots}
                            onChange={(e, val) => {
                              setFieldValue("carrots", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Corn"
                            name="corn"
                            value={values.corn}
                            onChange={(e, val) => {
                              setFieldValue("corn", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Eggplant"
                            name="eggplant"
                            value={values.eggplant}
                            onChange={(e, val) => {
                              setFieldValue("eggplant", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Lettuce"
                            name="lettuce"
                            value={values.lettuce}
                            onChange={(e, val) => {
                              setFieldValue("lettuce", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Onion"
                            name="onion"
                            value={values.onion}
                            onChange={(e, val) => {
                              setFieldValue("onion", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Mashed Potatoes"
                            name="mashedPotatoes"
                            value={values.mashedPotatoes}
                            onChange={(e, val) => {
                              setFieldValue("mashedPotatoes", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Fried Potatoes"
                            name="friedPotatoes"
                            value={values.friedPotatoes}
                            onChange={(e, val) => {
                              setFieldValue("friedPotatoes", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Baked Potatoes"
                            name="bakedPotatoes"
                            value={values.bakedPotatoes}
                            onChange={(e, val) => {
                              setFieldValue("bakedPotatoes", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Mashed Sweet Potatoes"
                            name="mashedSweetPotatoes"
                            value={values.mashedSweetPotatoes}
                            onChange={(e, val) => {
                              setFieldValue("mashedSweetPotatoes", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Fried Sweet Potatoes"
                            name="friedSweetPotatoes"
                            value={values.friedSweetPotatoes}
                            onChange={(e, val) => {
                              setFieldValue("friedSweetPotatoes", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Baked Sweet Potatoes"
                            name="bakedSweetPotatoes"
                            value={values.bakedSweetPotatoes}
                            onChange={(e, val) => {
                              setFieldValue("bakedSweetPotatoes", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Spinach"
                            name="spinach"
                            value={values.spinach}
                            onChange={(e, val) => {
                              setFieldValue("spinach", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Squash"
                            name="squash"
                            value={values.squash}
                            onChange={(e, val) => {
                              setFieldValue("squash", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Tomatoes"
                            name="tomatoes"
                            value={values.tomatoes}
                            onChange={(e, val) => {
                              setFieldValue("tomatoes", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Zucchini"
                            name="zucchini"
                            value={values.zucchini}
                            onChange={(e, val) => {
                              setFieldValue("zucchini", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="vegetableOther"
                            name="vegetableOther"
                            label={`Are there any specific vegetables you would like us to get?`}
                            value={values.vegetableOther}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.vegetableOther &&
                              Boolean(errors.vegetableOther)
                            }
                            helperText={
                              touched.vegetableOther && errors.vegetableOther
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h4">
                            <IconSalt /> Spices and Sauces
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Ginger"
                            name="ginger"
                            value={values.ginger}
                            onChange={(e, val) => {
                              setFieldValue("ginger", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Garlic"
                            name="garlic"
                            value={values.garlic}
                            onChange={(e, val) => {
                              setFieldValue("garlic", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Onion"
                            name="onion"
                            value={values.onion}
                            onChange={(e, val) => {
                              setFieldValue("onion", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Oregano"
                            name="oregano"
                            value={values.oregano}
                            onChange={(e, val) => {
                              setFieldValue("oregano", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Thyme"
                            name="thyme"
                            value={values.thyme}
                            onChange={(e, val) => {
                              setFieldValue("thyme", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Rosemary"
                            name="rosemary"
                            value={values.rosemary}
                            onChange={(e, val) => {
                              setFieldValue("rosemary", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Paprika"
                            name="papkrika"
                            value={values.paprika}
                            onChange={(e, val) => {
                              setFieldValue("paprika", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Black Pepper"
                            name="blackPepper"
                            value={values.blackPepper}
                            onChange={(e, val) => {
                              setFieldValue("blackPepper", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Soy Sauce"
                            name="soySauce"
                            value={values.soySauce}
                            onChange={(e, val) => {
                              setFieldValue("soySauce", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="A1 Sauce"
                            name="aOneSauce"
                            value={values.aOneSauce}
                            onChange={(e, val) => {
                              setFieldValue("aOneSauce", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Salt"
                            name="salt"
                            value={values.salt}
                            onChange={(e, val) => {
                              setFieldValue("salt", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Cumin"
                            name="cumin"
                            value={values.cumin}
                            onChange={(e, val) => {
                              setFieldValue("cumin", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Barbeque Sauce"
                            name="barbecueSauce"
                            value={values.barbecueSauce}
                            onChange={(e, val) => {
                              setFieldValue("barbecueSauce", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Chipotle"
                            name="chipotle"
                            value={values.chipotle}
                            onChange={(e, val) => {
                              setFieldValue("Chipotle", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Chili Powder"
                            name="chiliPowder"
                            value={values.chiliPowder}
                            onChange={(e, val) => {
                              setFieldValue("chiliPowder", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Cayenne"
                            name="cayanne"
                            value={values.cayanne}
                            onChange={(e, val) => {
                              setFieldValue("cayanne", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Nutmeg"
                            name="nutmeg"
                            value={values.nutmeg}
                            onChange={(e, val) => {
                              setFieldValue("nutmeg", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Cilantro"
                            name="cilantro"
                            value={values.cilantro}
                            onChange={(e, val) => {
                              setFieldValue("cilantro", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Teriyaki"
                            name="teriyaki"
                            value={values.teriyaki}
                            onChange={(e, val) => {
                              setFieldValue("teriyaki", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Ranch"
                            name="ranch"
                            value={values.ranch}
                            onChange={(e, val) => {
                              setFieldValue("ranch", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Ketchup"
                            name="ketchup"
                            value={values.ketchup}
                            onChange={(e, val) => {
                              setFieldValue("ketchup", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Mustard"
                            name="mustard"
                            value={values.mustard}
                            onChange={(e, val) => {
                              setFieldValue("mustard", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="otherSauce"
                            name="otherSauce"
                            label={`Are there any specific sauces or spices you would like us to get?`}
                            value={values.otherSauce}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.otherSauce &&
                              Boolean(errors.otherSauce)
                            }
                            helperText={
                              touched.otherSauce && errors.otherSauce
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        {(values.isVegetarian && !values.isEggDairy) ||
                        values.isVegan ? (
                          <Box />
                        ) : (
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        )}
                        {(values.isVegetarian && !values.isEggDairy) ||
                        values.isVegan ? (
                          <Box />
                        ) : (
                          <Grid item xs={12}>
                            <Typography variant="h4">
                              <IconCheese /> Cheeses
                            </Typography>
                          </Grid>
                        )}

                        <ConditionalFormRatingControl
                          isHidden={
                            (values.isVegetarian && !values.isEggDairy) ||
                            values.isVegan
                          }
                          label="Blue Cheese"
                          name="blueCheese"
                          value={values.blueCheese}
                          onChange={(e, val) => {
                            setFieldValue("blueCheese", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={
                            (values.isVegetarian && !values.isEggDairy) ||
                            values.isVegan
                          }
                          label="Cheddar"
                          name="chedddar"
                          value={values.cheddar}
                          onChange={(e, val) => {
                            setFieldValue("cheddar", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={
                            (values.isVegetarian && !values.isEggDairy) ||
                            values.isVegan
                          }
                          label="Provolone"
                          name="provolone"
                          value={values.provolone}
                          onChange={(e, val) => {
                            setFieldValue("provolone", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={
                            (values.isVegetarian && !values.isEggDairy) ||
                            values.isVegan
                          }
                          label="Swiss"
                          name="swiss"
                          value={values.swiss}
                          onChange={(e, val) => {
                            setFieldValue("swiss", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={
                            (values.isVegetarian && !values.isEggDairy) ||
                            values.isVegan
                          }
                          label="American"
                          name="american"
                          value={values.american}
                          onChange={(e, val) => {
                            setFieldValue("american", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={
                            (values.isVegetarian && !values.isEggDairy) ||
                            values.isVegan
                          }
                          label="Brie"
                          name="brie"
                          value={values.brie}
                          onChange={(e, val) => {
                            setFieldValue("brie", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={
                            (values.isVegetarian && !values.isEggDairy) ||
                            values.isVegan
                          }
                          label="Feta"
                          name="feta"
                          value={values.feta}
                          onChange={(e, val) => {
                            setFieldValue("feta", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={
                            (values.isVegetarian && !values.isEggDairy) ||
                            values.isVegan
                          }
                          label="Goat"
                          name="goat"
                          value={values.goat}
                          onChange={(e, val) => {
                            setFieldValue("goat", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={
                            (values.isVegetarian && !values.isEggDairy) ||
                            values.isVegan
                          }
                          label="Colby"
                          name="colby"
                          value={values.colby}
                          onChange={(e, val) => {
                            setFieldValue("colby", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={
                            (values.isVegetarian && !values.isEggDairy) ||
                            values.isVegan
                          }
                          label="Parmesan"
                          name="parmesan"
                          value={values.parmesan}
                          onChange={(e, val) => {
                            setFieldValue("parmesan", val);
                          }}
                        />
                        {(values.isVegetarian && !values.isEggDairy) ||
                        values.isVegan ? (
                          <Box />
                        ) : (
                          <Grid item xs={12}>
                            <TextField
                              id="cheeseOther"
                              name="cheeseOther"
                              label={`Are there any specific cheese you would like us to get?`}
                              value={values.cheeseOther}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.cheeseOther &&
                                Boolean(errors.cheeseOther)
                              }
                              helperText={
                                touched.cheeseOther && errors.cheeseOther
                              }
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h4">
                            <IconSunrise /> Breakfast
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Pancakes"
                            name="pancakes"
                            value={values.pancakes}
                            onChange={(e, val) => {
                              setFieldValue("pancakes", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Waffles"
                            name="waffles"
                            value={values.waffles}
                            onChange={(e, val) => {
                              setFieldValue("waffles", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Toast"
                            name="toast"
                            value={values.toast}
                            onChange={(e, val) => {
                              setFieldValue("toast", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Muffins"
                            name="muffins"
                            value={values.muffins}
                            onChange={(e, val) => {
                              setFieldValue("muffins", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Pastries"
                            name="pastries"
                            value={values.pastries}
                            onChange={(e, val) => {
                              setFieldValue("pastries", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Fruit"
                            name="fruit"
                            value={values.fruit}
                            onChange={(e, val) => {
                              setFieldValue("fruit", val);
                            }}
                          />
                        </Grid>
                        <ConditionalFormRatingControl
                          isHidden={
                            (values.isVegetarian && !values.isEggDairy) ||
                            values.isVegan
                          }
                          label="Hard Cheese"
                          name="hardCheese"
                          value={values.hardCheese}
                          onChange={(e, val) => {
                            setFieldValue("hardCheese", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={
                            (values.isVegetarian && !values.isEggDairy) ||
                            values.isVegan
                          }
                          label="Eggs"
                          name="eggs"
                          value={values.eggs}
                          onChange={(e, val) => {
                            setFieldValue("eggs", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={values.isVegetarian || values.isVegan}
                          label="Bacon"
                          name="bacon"
                          value={values.bacon}
                          onChange={(e, val) => {
                            setFieldValue("bacon", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={values.isVegetarian || values.isVegan}
                          label="Sausage"
                          name="breakfastSausage"
                          value={values.breakfastSausage}
                          onChange={(e, val) => {
                            setFieldValue("breakfastSausage", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={
                            (values.isVegetarian && !values.isEggDairy) ||
                            values.isVegan
                          }
                          label="Omelet"
                          name="omelet"
                          value={values.omelet}
                          onChange={(e, val) => {
                            setFieldValue("omelet", val);
                          }}
                        />
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Cereal"
                            name="cereal"
                            value={values.cereal}
                            onChange={(e, val) => {
                              setFieldValue("cereal", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Biscuits"
                            name="biscuits"
                            value={values.biscuits}
                            onChange={(e, val) => {
                              setFieldValue("biscuits", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Yogurt"
                            name="yogurt"
                            value={values.yogurt}
                            onChange={(e, val) => {
                              setFieldValue("yogurt", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="breakfastOther"
                            name="breakfastOther"
                            label={`Are there any specific breakfast options you would like us to get?`}
                            value={values.breakfastOther}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.breakfastOther &&
                              Boolean(errors.breakfastOther)
                            }
                            helperText={
                              touched.breakfastOther && errors.breakfastOther
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        {values.isVegetarian || values.isVegan ? (
                          <Box />
                        ) : (
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        )}
                        {values.isVegetarian || values.isVegan ? (
                          <Box />
                        ) : (
                          <Grid item xs={12}>
                            <Typography variant="h4">
                              <IconMeat /> Meat
                            </Typography>
                          </Grid>
                        )}
                        <ConditionalFormRatingControl
                          isHidden={values.isVegetarian || values.isVegan}
                          label="Beef"
                          name="beef"
                          value={values.beef}
                          onChange={(e, val) => {
                            setFieldValue("beef", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={values.isVegetarian || values.isVegan}
                          label="Chicken"
                          name="chicken"
                          value={values.chicken}
                          onChange={(e, val) => {
                            setFieldValue("chicken", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={values.isVegetarian || values.isVegan}
                          label="Pork"
                          name="pork"
                          value={values.pork}
                          onChange={(e, val) => {
                            setFieldValue("pork", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={values.isVegetarian || values.isVegan}
                          label="Sausage"
                          name="Sausage"
                          value={values.sausage}
                          onChange={(e, val) => {
                            setFieldValue("sausage", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={values.isVegetarian || values.isVegan}
                          label="Lamb"
                          name="lamb"
                          value={values.lamb}
                          onChange={(e, val) => {
                            setFieldValue("lamb", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={values.isVegetarian || values.isVegan}
                          label="Turkey"
                          name="turkey"
                          value={values.turkey}
                          onChange={(e, val) => {
                            setFieldValue("turkey", val);
                          }}
                        />
                        {values.isVegetarian || values.isVegan ? (
                          <Box />
                        ) : (
                          <Grid item xs={12}>
                            <TextField
                              id="meatOther"
                              name="meatOther"
                              label={`Are there any other meat you would like us to get?`}
                              value={values.meatOther}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.meatOther && Boolean(errors.meatOther)
                              }
                              helperText={touched.meatOther && errors.meatOther}
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                          </Grid>
                        )}
                        {values.isVegan ? (
                          <Box />
                        ) : (
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        )}
                        {values.isVegan ? (
                          <Box />
                        ) : (
                          <Grid item xs={12}>
                            <Typography variant="h4">
                              <IconFish /> Seafood
                            </Typography>
                          </Grid>
                        )}
                        <ConditionalFormRatingControl
                          isHidden={values.isVegan}
                          label="Ceviche"
                          name="ceviche"
                          value={values.ceviche}
                          onChange={(e, val) => {
                            setFieldValue("ceviche", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={values.isVegan}
                          label="Sushi"
                          name="sushi"
                          value={values.sushi}
                          onChange={(e, val) => {
                            setFieldValue("sushi", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={values.isVegan}
                          label="Cod"
                          name="cod"
                          value={values.cod}
                          onChange={(e, val) => {
                            setFieldValue("cod", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={values.isVegan}
                          label="Mahi Mahi"
                          name="mahiMahi"
                          value={values.mahiMahi}
                          onChange={(e, val) => {
                            setFieldValue("mahiMahi", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={values.isVegan}
                          label="Salmon"
                          name="salmon"
                          value={values.salmon}
                          onChange={(e, val) => {
                            setFieldValue("salmon", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={values.isVegan}
                          label="Sea Bass"
                          name="seaBass"
                          value={values.seaBass}
                          onChange={(e, val) => {
                            setFieldValue("seaBass", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={values.isVegan}
                          label="Snapper"
                          name="snapper"
                          value={values.snapper}
                          onChange={(e, val) => {
                            setFieldValue("snapper", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={values.isVegan}
                          label="Swordfish"
                          name="swordfish"
                          value={values.swordfish}
                          onChange={(e, val) => {
                            setFieldValue("swordfish", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={values.isVegan}
                          label="Tuna"
                          name="tuna"
                          value={values.tuna}
                          onChange={(e, val) => {
                            setFieldValue("tuna", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={values.isVegan}
                          label="Shrimp"
                          name="shrimp"
                          value={values.shrimp}
                          onChange={(e, val) => {
                            setFieldValue("shrimp", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={values.isVegan}
                          label="Lobster"
                          name="lobster"
                          value={values.lobster}
                          onChange={(e, val) => {
                            setFieldValue("lobster", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={values.isVegan}
                          label="Oysters"
                          name="oysters"
                          value={values.oysters}
                          onChange={(e, val) => {
                            setFieldValue("oysters", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={values.isVegan}
                          label="Clams"
                          name="clams"
                          value={values.clams}
                          onChange={(e, val) => {
                            setFieldValue("clams", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={values.isVegan}
                          label="Mussels"
                          name="mussels"
                          value={values.mussels}
                          onChange={(e, val) => {
                            setFieldValue("mussels", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={values.isVegan}
                          label="Calamari"
                          name="calamari"
                          value={values.calamari}
                          onChange={(e, val) => {
                            setFieldValue("calamari", val);
                          }}
                        />
                        <ConditionalFormRatingControl
                          isHidden={values.isVegan}
                          label="Octopus"
                          name="octopus"
                          value={values.octopus}
                          onChange={(e, val) => {
                            setFieldValue("octopus", val);
                          }}
                        />
                        {values.isVegan ? (
                          <Box />
                        ) : (
                          <Grid item xs={12}>
                            <TextField
                              id="seafoodOther"
                              name="seafoodOther"
                              label={`Are there any other seafood you would like us to get?`}
                              value={values.seafoodOther}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.seafoodOther &&
                                Boolean(errors.seafoodOther)
                              }
                              helperText={
                                touched.seafoodOther && errors.seafoodOther
                              }
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h4">
                            <IconToolsKitchen2 /> Soup / Rice / Pasta
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Curry"
                            name="curry"
                            value={values.curry}
                            onChange={(e, val) => {
                              setFieldValue("curry", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Hot Soup"
                            name="hotSoup"
                            value={values.hotSoup}
                            onChange={(e, val) => {
                              setFieldValue("hotSoup", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Cold Soup"
                            name="coldSoup"
                            value={values.coldSoup}
                            onChange={(e, val) => {
                              setFieldValue("coldSoup", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Rice"
                            name="rice"
                            value={values.rice}
                            onChange={(e, val) => {
                              setFieldValue("rice", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Pasta"
                            name="pasta"
                            value={values.pasta}
                            onChange={(e, val) => {
                              setFieldValue("pasta", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Bread"
                            name="bread"
                            value={values.bread}
                            onChange={(e, val) => {
                              setFieldValue("bread", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Risotto"
                            name="risotto"
                            value={values.risotto}
                            onChange={(e, val) => {
                              setFieldValue("risotto", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="pastaOther"
                            name="pastaOther"
                            label={`Are there any other Soup / Rice / Pasta you would like us to make?`}
                            value={values.pastaOther}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.pastaOther && Boolean(errors.pastaOther)
                            }
                            helperText={touched.pastaOther && errors.pastaOther}
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h4">
                            <IconCookie /> Desserts
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Cookies"
                            name="cookie"
                            value={values.cookie}
                            onChange={(e, val) => {
                              setFieldValue("cookie", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Cake"
                            name="cake"
                            value={values.cake}
                            onChange={(e, val) => {
                              setFieldValue("cake", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Pastries"
                            name="dessertPastries"
                            value={values.dessertPastries}
                            onChange={(e, val) => {
                              setFieldValue("dessertPastries", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Ice Cream"
                            name="iceCream"
                            value={values.iceCream}
                            onChange={(e, val) => {
                              setFieldValue("iceCream", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Donuts"
                            name="donuts"
                            value={values.donuts}
                            onChange={(e, val) => {
                              setFieldValue("donuts", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Brownies"
                            name="brownies"
                            value={values.brownies}
                            onChange={(e, val) => {
                              setFieldValue("brownies", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormRatingControl
                            label="Confectionaries"
                            name="confectionery"
                            value={values.confectionery}
                            onChange={(e, val) => {
                              setFieldValue("confectionery", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="dessertOther"
                            name="dessertOther"
                            label={`Are there any other Desserts you would like us to make?`}
                            value={values.dessertOther}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.dessertOther &&
                              Boolean(errors.dessertOther)
                            }
                            helperText={
                              touched.dessertOther && errors.dessertOther
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="otherDishes"
                            name="otherDishes"
                            label={`Are there any specific dishes you would like us to try to make?`}
                            value={values.otherDishes}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.otherDishes && Boolean(errors.otherDishes)
                            }
                            helperText={
                              touched.otherDishes && errors.otherDishes
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            type="submit"
                            sx={{ mt: 2 }}
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
