import {
  AppBar,
  Box,
  Container,
  Divider,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { Support } from "@mui/icons-material";
import ZSailLogo from "../ZSailLogo/ZSailLogo";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { CurrentPassengerState } from "../../recoil/PassengerAtom";

export default function PassengerAppBar() {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [currentPassenger] = useRecoilState(CurrentPassengerState);


  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <ZSailLogo
            sx={{ display: { xs: "none", md: "none", lg: "flex" }, mr: 1 }}
            height={30}
            width={30}
          />
          <Stack direction="row">
            <Typography
              variant="h6"
              noWrap
              component="a"
              sx={{
                mr: 0.5,
                display: { xs: "none", md: "none", lg: "flex" },
                color: "inherit",
                textDecoration: "none",
              }}
            >
              {`${currentPassenger.ship?.name} Passenger Portal`}
            </Typography>
          </Stack>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "flex", lg: "none" },
            }}
          >
          </Box>
          <ZSailLogo
            sx={{ display: { xs: "flex", md: "flex", lg: "none" }, mr: 1 }}
            height={24}
            width={24}
          />
          <Typography
            variant="h5"
            noWrap
            sx={{
              mr: 2,
              display: { xs: "flex", md: "flex", lg: "none" },
              flexGrow: 1,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            {`${currentPassenger.ship?.name} Passenger Portal`}
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "none", lg: "flex" },
            }}
          ></Box>
          <Box sx={{ flexGrow: 0 }}>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <Divider />
              <MenuItem
                key="supportLink"
                onClick={() => {
                  window.open(
                    "https://philo.atlassian.net/servicedesk/customer/portal/1",
                    "_blank"
                  );
                }}
              >
                <Support sx={{ mr: 2 }} /> Support
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
