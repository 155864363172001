import { Box, Step, StepLabel, Stepper } from "@mui/material";

export type OnboardingStepperProps = {
  activeStep: number;
};
export default function OnboardingStepper(props: OnboardingStepperProps) {
  const { activeStep } = props;
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        <Step key={0}>
          <StepLabel>Contact Info</StepLabel>
        </Step>
        <Step key={1}>
          <StepLabel>Travel Info</StepLabel>
        </Step>
        <Step key={2}>
          <StepLabel>Medical Info</StepLabel>
        </Step>
        <Step key={3}>
          <StepLabel>Aquatic Proficiency</StepLabel>
        </Step>
        <Step key={4}>
          <StepLabel>Activities</StepLabel>
        </Step>
        <Step key={5}>
          <StepLabel>Drink Prefernces</StepLabel>
        </Step>
        <Step key={6}>
          <StepLabel>Food Preferences</StepLabel>
        </Step>
      </Stepper>
    </Box>
  );
}
