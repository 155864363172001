import { ArrowRightAlt } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { countries } from "country-codes-flags-phone-codes";
import { Formik } from "formik";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import OnboardingStepper from "../components/OnboardingStepper/OnboardingStepper";
import TripCard from "../components/TripOverview/TripCard";
import { CurrentPassengerState } from "../recoil/PassengerAtom";
import {
  createContactInfo,
  getPassengerDetails,
} from "../services/passengerService";
import { PassengerContactInfo } from "../types/passenger";

export default function ContactInfo() {
  const [currentPassenger, setCurrentPassenger] = useRecoilState(
    CurrentPassengerState
  );
  const navigate = useNavigate();
  var initialValues = {
    firstName: currentPassenger.firstName,
    lastName: currentPassenger.lastName,
    proNouns: "",
    otherProNouns: "",
    dialCode: "",
    phoneNumber: "",
    email: currentPassenger.email,
    dateOfBirth: DateTime.now(),
    addressLine1: "",
    addressLine2: "",
    city: "",
    region: "",
    postalCode: "",
    country: "",
    emergencyFirstName: "",
    emergencyLastName: "",
    emergencyDialCode: "",
    emergencyPhoneNumber: "",
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <OnboardingStepper activeStep={0} />
        </Grid>
        <Grid item xs={12}>
          <TripCard
            startDate={currentPassenger.trip?.startDate}
            startLocation={currentPassenger.trip?.startLocation}
            endDate={currentPassenger.trip?.endDate}
            endLocation={currentPassenger.trip?.endLocation}
            firstName={currentPassenger.firstName}
            lastName={currentPassenger.lastName}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 4,
              borderTopColor: "#b47f49",
              borderTopWidth: "4px",
              borderStyle: "solid",
              borderRightWidth: "0px",
              borderLeftWidth: "0px",
              borderBottomWidth: "0",
            }}
            elevation={12}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h4">Contact Information</Typography>
              </Grid>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Formik
                  initialValues={initialValues}
                  onSubmit={(values) => {
                    var contactInfo: PassengerContactInfo = {
                      firstName: values.firstName,
                      lastName: values.lastName,
                      proNouns:
                        values.proNouns === "other"
                          ? values.otherProNouns
                          : values.proNouns,
                      dialCode: values.dialCode,
                      phoneNumber: values.phoneNumber,
                      email: values.email,
                      dateOfBirth: values.dateOfBirth,
                      addressLine1: values.addressLine1,
                      addressLine2: values.addressLine2,
                      city: values.city,
                      region: values.region,
                      postalCode: values.postalCode,
                      country: values.country,
                      emergencyFirstName: values.emergencyFirstName,
                      emergencyLastName: values.emergencyLastName,
                      emergencyDialCode: values.emergencyDialCode,
                      emergencyPhone: values.emergencyPhoneNumber,
                    };
                    createContactInfo(currentPassenger.id, contactInfo).then(
                      (res) => {
                        getPassengerDetails(currentPassenger).then((res) => {
                          setCurrentPassenger(res.data);
                          navigate("/");
                        });
                      }
                    );
                  }}
                >
                  {({
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id="firstName"
                            name="firstName"
                            label={`First Name`}
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            error={
                              touched.firstName && Boolean(errors.firstName)
                            }
                            helperText={touched.firstName && errors.firstName}
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id="lastName"
                            name="lastName"
                            required
                            label={`Last Name`}
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.lastName && Boolean(errors.lastName)}
                            helperText={touched.lastName && errors.lastName}
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <InputLabel id="proNouns">Pronouns</InputLabel>
                          <Select
                            labelId="proNouns"
                            id="proNouns"
                            required
                            value={values.proNouns}
                            onChange={(e) => {
                              setFieldValue("proNouns", e.target.value);
                            }}
                            fullWidth
                            sx={{ mb: 2 }}
                          >
                            <MenuItem value={"He/Him"}>{"He/Him"}</MenuItem>
                            <MenuItem value={"She/Her"}>{"She/Her"}</MenuItem>
                            <MenuItem value={"They/Them"}>
                              {"They/Them"}
                            </MenuItem>
                            <MenuItem value={"other"}>{"other"}</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          {values.proNouns === "other" ? (
                            <TextField
                              id="otherProNouns"
                              name="otherProNouns"
                              label={`Pronouns`}
                              value={values.otherProNouns}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.otherProNouns &&
                                Boolean(errors.otherProNouns)
                              }
                              helperText={
                                touched.otherProNouns && errors.otherProNouns
                              }
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                          ) : (
                            <Box />
                          )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Stack direction="row" spacing={2}>
                            <Select
                              labelId="dialCode"
                              id="dialCode"
                              required
                              value={values.dialCode}
                              onChange={(e) => {
                                setFieldValue("dialCode", e.target.value);
                              }}
                              sx={{ mb: 2 }}
                            >
                              {countries
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((country) => (
                                  <MenuItem value={country.dialCode}>
                                    {country.name}
                                  </MenuItem>
                                ))}
                            </Select>
                            <TextField
                              id="phoneNumber"
                              name="phoneNumber"
                              label={`Phone Number`}
                              value={values.phoneNumber}
                              required
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.phoneNumber &&
                                Boolean(errors.phoneNumber)
                              }
                              helperText={
                                touched.phoneNumber && errors.phoneNumber
                              }
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id="email"
                            name="email"
                            label={`Email Address`}
                            value={values.email}
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.email && Boolean(errors.email)}
                            helperText={touched.email && errors.email}
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Stack>
                            <DatePicker
                              disableFuture
                              label="Date of Birth"
                              value={values.dateOfBirth}
                              onChange={(value: any) =>
                                setFieldValue(
                                  "dateOfBirth",
                                  DateTime.fromISO(value),
                                  true
                                )
                              }
                              sx={{ mb: 2 }}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          <Stack direction="column">
                            <Typography variant="h6">
                              Mailing Address
                            </Typography>
                            <Typography variant="body1">
                              {
                                "We ask this so we can mail you any belongings you may accidentally leave behind during your travels"
                              }
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="addressLine1"
                            name="addressLine1"
                            required
                            label={`Address Line 1`}
                            value={values.addressLine1}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.addressLine1 &&
                              Boolean(errors.addressLine1)
                            }
                            helperText={
                              touched.addressLine1 && errors.addressLine1
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="addressLine2"
                            name="addressLine2"
                            label={`Address Line 2`}
                            value={values.addressLine2}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.addressLine2 &&
                              Boolean(errors.addressLine2)
                            }
                            helperText={
                              touched.addressLine2 && errors.addressLine2
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            id="city"
                            name="city"
                            required
                            label={`City`}
                            value={values.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.city && Boolean(errors.city)}
                            helperText={touched.city && errors.city}
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            id="region"
                            name="region"
                            label={`State/Province/Region`}
                            required
                            value={values.region}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.region && Boolean(errors.region)}
                            helperText={touched.region && errors.region}
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            id="postalCode"
                            name="postalCode"
                            label={`Postal Code`}
                            required
                            value={values.postalCode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.postalCode && Boolean(errors.postalCode)
                            }
                            helperText={touched.postalCode && errors.postalCode}
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            id="country"
                            name="country"
                            label={`Country`}
                            required
                            value={values.country}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.country && Boolean(errors.country)}
                            helperText={touched.country && errors.country}
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h6">
                            Emergency Contact
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id="emergencyFirstName"
                            name="emergencyFirstName"
                            label={`First Name`}
                            required
                            value={values.emergencyFirstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.emergencyFirstName &&
                              Boolean(errors.emergencyFirstName)
                            }
                            helperText={
                              touched.emergencyFirstName &&
                              errors.emergencyFirstName
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id="emergencyLastName"
                            name="emergencyLastName"
                            label={`Last Name`}
                            required
                            value={values.emergencyLastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.emergencyLastName &&
                              Boolean(errors.emergencyLastName)
                            }
                            helperText={
                              touched.emergencyLastName &&
                              errors.emergencyLastName
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Stack direction="row" spacing={2}>
                            <Select
                              labelId="emergencyDialCode"
                              id="emergencyDialCode"
                              value={values.emergencyDialCode}
                              required
                              onChange={(e) => {
                                setFieldValue(
                                  "emergencyDialCode",
                                  e.target.value
                                );
                              }}
                              sx={{ mb: 2 }}
                            >
                              {countries
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((country) => (
                                  <MenuItem value={country.dialCode}>
                                    {country.name}
                                  </MenuItem>
                                ))}
                            </Select>
                            <TextField
                              id="emergencyPhoneNumber"
                              name="emergencyPhoneNumber"
                              label={`Phone Number`}
                              required
                              value={values.emergencyPhoneNumber}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.emergencyPhoneNumber &&
                                Boolean(errors.emergencyPhoneNumber)
                              }
                              helperText={
                                touched.emergencyPhoneNumber &&
                                errors.emergencyPhoneNumber
                              }
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            type="submit"
                            sx={{ mt: 2 }}
                          >
                            Next <ArrowRightAlt />
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
