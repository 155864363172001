import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import TripCard from "../components/TripOverview/TripCard";
import OnboardingStepper from "../components/OnboardingStepper/OnboardingStepper";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { CurrentPassengerState } from "../recoil/PassengerAtom";
import { Formik } from "formik";
import { ArrowRightAlt } from "@mui/icons-material";
import { PassengerMedicalInfo } from "../types/passenger";
import {
  createMedicalInfo,
  getPassengerDetails,
} from "../services/passengerService";

export default function MedicalInfo() {
  const [currentPassenger, setCurrentPassenger] = useRecoilState(
    CurrentPassengerState
  );
  const navigate = useNavigate();
  const initialValues = {
    allergies: "",
    foodAllergies: "",
    medicalConditionsOrDisabilities: "",
    useMobilityAids: false,
    useHearingAidsorCochlearImplants: false,
    haveSeasickness: false,
    useSeasicknessMedicine: false,
  };
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <OnboardingStepper activeStep={2} />
        </Grid>
        <Grid item xs={12}>
          <TripCard
            startDate={currentPassenger.trip?.startDate}
            startLocation={currentPassenger.trip?.startLocation}
            endDate={currentPassenger.trip?.endDate}
            endLocation={currentPassenger.trip?.endLocation}
            firstName={currentPassenger.firstName}
            lastName={currentPassenger.lastName}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 4,
              borderTopColor: "#b47f49",
              borderTopWidth: "4px",
              borderStyle: "solid",
              borderRightWidth: "0px",
              borderLeftWidth: "0px",
              borderBottomWidth: "0",
            }}
            elevation={12}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h4">Medical Information</Typography>
              </Grid>
              <Grid item xs={12}>
                <Alert severity="info">
                  <Stack direction="column">
                    <Typography variant="h6">
                      Medical Privacy Statement
                    </Typography>
                    <Typography variant="body1">
                      {
                        "The personal and health information collected in this form, and attachments, will be managed securely and accessed only by staff , on a need-to-know basis, and in accordance with all appropriate privacy laws. In the event of a medical emergency, information will be provided, as required, to emergency services and medical practitioners. "
                      }
                    </Typography>
                    <Typography variant="body1">
                      {
                        "You are not required to provide us any private medical information. We ask only in the interest of your health and safety and to provide you with better service."
                      }
                    </Typography>
                  </Stack>
                </Alert>
              </Grid>
              <Grid item xs={12}>
                <Formik
                  initialValues={initialValues}
                  onSubmit={(values) => {
                    var medicalInfo: PassengerMedicalInfo = {
                      allergies: values.allergies,
                      foodAllergies: values.foodAllergies,
                      medicalConditionsOrDisabilities:
                        values.medicalConditionsOrDisabilities,
                      useMobilityAids: values.useMobilityAids,
                      useHearingAidsorCochlearImplants:
                        values.useHearingAidsorCochlearImplants,
                      haveSeasickness: values.haveSeasickness,
                      useSeasicknessMedicine: values.useSeasicknessMedicine,
                    };
                    createMedicalInfo(currentPassenger.id, medicalInfo).then(
                      (res) => {
                        getPassengerDetails(currentPassenger).then((res) => {
                          setCurrentPassenger(res.data);
                          navigate("/");
                        });
                      }
                    );
                  }}
                >
                  {({
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    touched,
                    values,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            id="allergies"
                            name="allergies"
                            label={`Do you have any allergies you would like us to know about?`}
                            value={values.allergies}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.allergies && Boolean(errors.allergies)
                            }
                            helperText={touched.allergies && errors.allergies}
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="foodAllergies"
                            name="foodAllergies"
                            label={`Do you have any food allergies or sensitivities you would like us to know about?`}
                            value={values.foodAllergies}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.foodAllergies &&
                              Boolean(errors.foodAllergies)
                            }
                            helperText={
                              touched.foodAllergies && errors.foodAllergies
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="medicalConditionsOrDisabilities"
                            name="medicalConditionsOrDisabilities"
                            label={`Do you have any medical conditions or disabilities that we should know about?`}
                            value={values.medicalConditionsOrDisabilities}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.medicalConditionsOrDisabilities &&
                              Boolean(errors.medicalConditionsOrDisabilities)
                            }
                            helperText={
                              touched.medicalConditionsOrDisabilities &&
                              errors.medicalConditionsOrDisabilities
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={values.useMobilityAids} />
                            }
                            label="Do you use mobility aids?"
                            name="useMobilityAids"
                            onChange={handleChange}
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  values.useHearingAidsorCochlearImplants
                                }
                              />
                            }
                            label="Do you use Hearing Aids or Cochlear Implants?"
                            name="useHearingAidsorCochlearImplants"
                            onChange={handleChange}
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={values.haveSeasickness} />
                            }
                            label="Do you have seasickness?"
                            name="haveSeasickness"
                            onChange={handleChange}
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        {values.haveSeasickness ? (
                          <Grid item xs={12} md={6}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values.useSeasicknessMedicine}
                                />
                              }
                              label="Would you like to be provided with seasickness treatments including dramamine, seasickness wristbands, etc?"
                              name="useSeasicknessMedicine"
                              onChange={handleChange}
                              sx={{ mb: 2 }}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        <Grid item xs={12}>
                          <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            type="submit"
                            sx={{ mt: 2 }}
                          >
                            Next <ArrowRightAlt />
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
