import axios from "axios";
import { PassengerFoodInformation } from "../types/food";
import {
  Passenger,
  PassengerActivityInfo,
  PassengerAquaticInfo,
  PassengerContactInfo,
  PassengerDrinkInformationDTO,
  PassengerID,
  PassengerMedicalInfo,
  PassengerTravelInfo,
} from "../types/passenger";

//const API_URL: string = "https://localhost:7046/";
const API_URL: string = "https://api.zsail.io/";

export const getPassengerDetails = (passenger: Passenger) => {
  var id: PassengerID = {
    email: passenger.email,
    joinCode: passenger.joinCode,
  };
  return axios.post<Passenger>(`${API_URL}passengers`, id).then((response) => {
    return response;
  });
};

export const createContactInfo = (
  passengerId?: string,
  contactInfo?: PassengerContactInfo
) => {
  return axios
    .post(`${API_URL}passengers/${passengerId}/contactinfo`, contactInfo)
    .then((res) => {
      return res.data;
    });
};

export const createTravelInfo = (
  passengerId?: string,
  travelInfo?: PassengerTravelInfo
) => {
  return axios
    .post(`${API_URL}passengers/${passengerId}/travelinfo`, travelInfo)
    .then((res) => {
      return res.data;
    });
};

export const createMedicalInfo = (
  passengerId?: string,
  medicalInfo?: PassengerMedicalInfo
) => {
  return axios
    .post(`${API_URL}passengers/${passengerId}/medicalinfo`, medicalInfo)
    .then((res) => {
      return res.data;
    });
};
export const createAquaticInfo = (
  passengerId?: string,
  aquaticInfo?: PassengerAquaticInfo
) => {
  return axios
    .post(`${API_URL}passengers/${passengerId}/aquaticinfo`, aquaticInfo)
    .then((res) => {
      return res.data;
    });
};

export const createActivityInfo = (
  passengerId?: string,
  activity?: PassengerActivityInfo
) => {
  return axios
    .post(`${API_URL}passengers/${passengerId}/activityinfo`, activity)
    .then((res) => {
      return res.data;
    });
};

export const createDrinkInfo = (
  passengerId?: string,
  drinkInfo?: PassengerDrinkInformationDTO
) => {
  return axios
    .post(`${API_URL}passengers/${passengerId}/drinkinfo`, drinkInfo)
    .then((res) => {
      return res.data;
    });
};

export const createFoodInfo = (
  passengerId?: string,
  foodInfo?: PassengerFoodInformation
) => {
  return axios
    .post(`${API_URL}passengers/${passengerId}/foodinfo`, foodInfo)
    .then((res) => {
      return res.data;
    });
};

export const uploadPassportFile = (
  uploadProgressFunction: Function,
  passengerId?: string,
  travelInformationId?: string,
  formData?: FormData
) => {
  return axios.post(
    `${API_URL}passengers/file/${travelInformationId}?passengerId=${passengerId}`,
    formData,
    {
      onUploadProgress: (progressEvent) => {
        uploadProgressFunction(progressEvent);
      },
    }
  );
};
