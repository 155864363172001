import {
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { CurrentPassengerState } from "../recoil/PassengerAtom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TripCard from "../components/TripOverview/TripCard";
import { Logout } from "@mui/icons-material";

export default function Dashboard() {
  const [currentPassenger, setCurrentPassenger] = useRecoilState(
    CurrentPassengerState
  );
  const navigate = useNavigate();
  useEffect(() => {
    switch (currentPassenger.onBoardingStatus) {
      case 0:
        navigate(`/${currentPassenger.ship?.name}/ContactInfo`);
        break;
      case 1:
        navigate(`/${currentPassenger.ship?.name}/TravelInfo`);
        break;
      case 2:
        navigate(`/${currentPassenger.ship?.name}/MedicalInfo`);
        break;
      case 3:
        navigate(`/${currentPassenger.ship?.name}/AquaticInfo`);
        break;
      case 4:
        navigate(`/${currentPassenger.ship?.name}/Activities`);
        break;
      case 5:
        navigate(`/${currentPassenger.ship?.name}/Drinks`);
        break;
      case 6:
        navigate(`/${currentPassenger.ship?.name}/Food`);
        break;
      default:
        break;
    }
  }, [
    currentPassenger.id,
    currentPassenger.onBoardingStatus,
    navigate,
    currentPassenger.ship?.name,
  ]);
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TripCard
            startDate={currentPassenger.trip?.startDate}
            startLocation={currentPassenger.trip?.startLocation}
            endDate={currentPassenger.trip?.endDate}
            endLocation={currentPassenger.trip?.endLocation}
            firstName={currentPassenger.firstName}
            lastName={currentPassenger.lastName}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 4,
              borderTopColor: "#b47f49",
              borderTopWidth: "4px",
              borderStyle: "solid",
              borderRightWidth: "0px",
              borderLeftWidth: "0px",
              borderBottomWidth: "0",
            }}
            elevation={12}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h4">
                  Thank you for your submission! A member from our staff will
                  reach out with any questions.
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row-reverse">
            <Button
              startIcon={<Logout />}
              onClick={() =>
                setCurrentPassenger({
                  id: "",
                  email: "",
                  joinCode: "",
                  onBoardingStatus: -1,
                })
              }
              variant="contained"
            >
              Logout
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}
